import { inject, Injectable } from '@angular/core';
import { TemplateState } from './states';
import { Template } from '../domain/models/template.model';
import { CoreStore } from './core.store';
import { TemplateAPIService } from '../services/apis';

@Injectable({
  providedIn: 'root',
})
export class TemplateStore extends CoreStore<Template, TemplateState, TemplateAPIService> {
  protected override serviceType = TemplateAPIService;

  constructor() {
    super({ entities: [], loading: false, error: '', selectedEntity: undefined });
  }
}
