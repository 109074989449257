import { Directory, Filesystem } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';
import { Template } from 'src/app/domain/models/template.model';

class ImageCacheManager {
  async cacheImage(url: string, filename: string): Promise<string> {
    const options: HttpOptions = { url, responseType: 'blob' };
    const response = await CapacitorHttp.get(options);
    const base64data = await response.data;
    const mimeType = response.headers['content-type'] || this.getMimeTypeFromFilename(filename);

    await this.checkAndDeleteFileIfExists(filename);

    await Filesystem.writeFile({
      path: filename,
      data: base64data,
      directory: Directory.Data,
    });

    return `data:${mimeType};base64,${base64data}`;
  }

  private async checkAndDeleteFileIfExists(filename: string): Promise<void> {
    try {
      await Filesystem.readFile({ path: filename, directory: Directory.Data });
      await Filesystem.deleteFile({ path: filename, directory: Directory.Data });
    } catch (error) {
      //console.error(`File operation error:`, error, filename);
    }
  }

  private getMimeTypeFromFilename(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'svg':
        return 'image/svg+xml';
      default:
        return 'application/octet-stream';
    }
  }

  async getFromCache(filename: string): Promise<string | null> {
    try {
      const file = await Filesystem.readFile({ directory: Directory.Data, path: filename });
      return `data:image/svg+xml;base64,${file.data}`;
    } catch (error) {
      return null;
    }
  }

  async isOnline(): Promise<boolean> {
    const status = await Network.getStatus();
    return status.connected;
  }

  public extractFilenameFromUrl(url: string): string {
    const urlObj = new URL(url);
    return urlObj.pathname.split('/').pop() ?? 'default_filename';
  }

  async cacheAllImagesFromTemplate(templates: Template[]): Promise<void> {
    for (let template of templates) {
      for (let field of template.fields) {
        if (field.icon) {
          const filename = this.extractFilenameFromUrl(field.icon);
          let cachedData = await this.getFromCache(filename);

          if (!cachedData && (await this.isOnline())) {
            console.info('Fetching image from network...');
            await this.cacheImage(field.icon, filename).catch(
              (error) => {},
              //console.error('Error fetching or caching image:', error, filename),
            );
          }
        }
      }
    }
  }
}

export default ImageCacheManager;
