import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFallbackImg]',
  standalone: true,
})
export class FallbackImgDirective {
  private fallbackImg = '/assets/icons/svg/dummy.svg'; // Your fallback image path

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('error')
  onError() {
    this.setImgSrc(this.fallbackImg);
  }

  private setImgSrc(src: string) {
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);
  }
}
