import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastMessage, ToastService } from 'src/app/services/components/toast.service';

@Component({
  selector: 'app-toast',
  template: `
    @if (toast) {
      <div class="fixed bottom-4 right-4 p-4 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            @if (toast.icon) {
              <img [src]="toast!.icon" alt="Icon" class="h-6 w-6" />
            }
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">{{ toast!.title }}</p>
            <p class="mt-1 text-sm text-gray-500">{{ toast!.message }}</p>
            <div class="mt-4 flex">
              @if (toast.button) {
                <button
                  (click)="toast!.button!.action()"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {{ toast!.button!.text }}
                </button>
              }
            </div>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" (click)="closeToast()">
              <span class="sr-only">Close</span>
              <!-- Icon for close button -->
            </button>
          </div>
        </div>
      </div>
    }
  `,
  standalone: true,
})
export class ToastComponent implements OnDestroy {
  toast: ToastMessage | null = null;
  private toastSubscription: Subscription;

  constructor(private toastService: ToastService) {
    this.toastSubscription = this.toastService.toastState.subscribe((toast: ToastMessage) => {
      this.toast = toast;
      if (toast.duration) {
        setTimeout(() => this.closeToast(), toast.duration);
      }
    });
  }

  ngOnDestroy(): void {
    this.toastSubscription.unsubscribe();
  }

  closeToast() {
    this.toast = null;
  }
}
