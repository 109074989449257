import { Component, computed, forwardRef, inject, input, model, signal } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { LocationSelectorModalComponent } from './location-selector-modal.component';
import { DefaultMapLocation } from 'src/app/domain';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-location-selector',
  template: `<div class="flex flex-col w-full py-4 px-2 border border-primary-200 rounded-md" (click)="openModal()">
    <p class="text-primary-700">{{ selectorText() }}</p>
  </div> `,
  standalone: true,
  imports: [],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectorComponent),
      multi: true,
    },
  ],
})
export class LocationSelectorComponent implements ControlValueAccessor {
  modalCtrl = inject(ModalController);
  location = signal<DefaultMapLocation | null>(null);

  private onChange!: (location: DefaultMapLocation) => void;
  private onTouched!: () => void;
  private isDisabled: boolean = false;

  selectorText = computed(() => {
    const location = this.location();
    if (!location) {
      return 'Select your location';
    }

    const fields = [
      location.location,
      location.level4?.name,
      location.level3?.name,
      location.level2?.name,
      location.level1?.name,
      location.country?.name,
    ];

    const selectedText = fields.find((field) => field && field !== '');
    return selectedText || 'Select your location';
  });

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: LocationSelectorModalComponent,
      animated: true,
      mode: 'ios',
      componentProps: {
        location: this.location(),
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role !== 'cancel') {
      this.location.set(data);
      this.onChange(data);
    }
  }

  writeValue(location: DefaultMapLocation): void {
    this.location.set(location);
  }

  registerOnChange(fn: (location: DefaultMapLocation) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  touch() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
