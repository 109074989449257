import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { Notification } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService extends CoreApiService<Notification> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<Notification>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'notifications';
  protected getServiceIdentifier(): string {
    return 'NotificationApiService';
  }
}
