import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'base64',
  standalone: true,
})
export class Base64Pipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  async transform(url: string): Promise<SafeUrl> {
    const base64String = await this.convertToBase64(url);
    return this.sanitizer.bypassSecurityTrustUrl(base64String);
  }

  private convertToBase64(url: string): Promise<string> {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );
  }
}
