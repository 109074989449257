import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { HelpSupport } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';

@Injectable({
  providedIn: 'root',
})
export class HelpSupportApiService extends CoreApiService<HelpSupport> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<HelpSupport>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'helps';
  protected getServiceIdentifier(): string {
    return 'HelpApiService';
  }
}
