import { Injectable, NgZone } from '@angular/core';
import { Auth, User, onAuthStateChanged } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  unsubscribe: any;

  constructor(
    private auth: Auth,
    private ngZone: NgZone,
    private router: Router,
  ) {
    this.initAuthListener();
  }

  private initAuthListener() {
    this.unsubscribe = onAuthStateChanged(this.auth, (user) => {
      this.ngZone.run(() => {
        this.userSubject.next(user);
      });
    });
  }

  async checkAuthState(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(this.auth, (user) => {
        unsubscribe();
        resolve(user);
      });
    });
  }

  stopListener() {
    this.unsubscribe();
  }
}
