import { Injectable } from '@angular/core';
import { CoreStore } from './core.store';
import { Organization } from '../domain';
import { OrganizationState } from './states';
import { OrganizationAPIService } from '../services/apis';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStore extends CoreStore<Organization, OrganizationState, OrganizationAPIService> {
  protected override serviceType = OrganizationAPIService;

  constructor() {
    super({ entities: [], loading: false, error: '', selectedEntity: undefined });
  }
}
