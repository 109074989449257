import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, single } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading = signal<boolean>(false);
  public loadingRequestCount = signal<number>(0);

  show() {
    this.loadingRequestCount.update((count) => count + 1);
    this.loading.set(true);
  }

  hide() {
    this.loadingRequestCount.update((count) => count - 1);
    if (this.loadingRequestCount() <= 0) this.loading.set(false);
  }
}
