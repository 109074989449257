import { Haptics, ImpactStyle } from '@capacitor/haptics';

export class HapticHelper {
  static async vibrate() {
    await Haptics.vibrate();
  }

  static async impact() {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });
  }
}
