import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';

export async function captureModalContentAndGeneratePDF() {
  const modalContent = document.getElementById('chartContainer') as HTMLElement;

  if (modalContent) {
    preloadImages(modalContent, async () => {
      await generatePDF();
    });
  }

  async function generatePDF() {
    const canvas = await html2canvas(modalContent, {
      useCORS: true,
      logging: true,
      scale: 2,
      x: 0,
      y: 0,
      scrollX: 0,
      scrollY: 0,
      width: modalContent.offsetWidth,
      height: modalContent.offsetHeight,
    });

    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, -100, pdfWidth, pdfHeight);

    if (Capacitor.isNativePlatform()) {
      const pdfOutput = pdf.output('blob');
      await saveAndSharePDF(pdfOutput);
    } else pdf.save('report.pdf');
  }

  async function preloadImages(container: HTMLElement, callback: () => void) {
    const images = container.getElementsByTagName('img');
    let loaded = 0;
    for (let i = 0; i < images.length; i++) {
      if (images[i].complete) {
        loaded++;
        if (loaded === images.length) callback();
      } else {
        images[i].addEventListener('load', function () {
          loaded++;
          if (loaded === images.length) callback();
        });
        images[i].addEventListener('error', function () {
          loaded++; // Consider error handling for failed images
          if (loaded === images.length) callback();
        });
      }
    }
    if (images.length === 0) callback(); // Callback immediately if no images
  }

  async function saveAndSharePDF(pdfBlob: Blob) {
    // Convert the blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = async () => {
      const base64data = reader.result;

      // Save the PDF to the filesystem
      const fileName = `report-${new Date().getTime()}.pdf`;
      try {
        const saveResult = await Filesystem.writeFile({
          path: fileName,
          data: base64data as string, // Add type assertion
          directory: Directory.Documents,
          recursive: true,
        });

        // Get the URI of the file to share
        const fileUri = await Filesystem.getUri({
          directory: Directory.Documents,
          path: fileName,
        });

        // Share the PDF file
        await Share.share({
          title: 'PDF Report',
          text: 'Here is the PDF report generated.',
          url: fileUri.uri, // Use the URI for sharing
          dialogTitle: 'Share PDF report',
        });
      } catch (error) {
        console.error('Error saving or sharing PDF:', error);
      }
    };
  }
}
