import { Component, DestroyRef, Input, booleanAttribute, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FallbackImgDirective } from '../directives/fallback-image.directive';
@Component({
  selector: 'app-back-button',
  template: `
    <div class="back-button">
      <button class="btn btn-circle btn-ghost btn-sm ripple-button" (click)="onBack()">
        <img appFallbackImg src="assets/icons/arrow-left.svg" width="24" height="24" alt="arrow-left" />
        <span class="ripple"></span>
      </button>
    </div>
  `,
  standalone: true,
  imports: [FallbackImgDirective],
})
export class BackButtonComponent {
  @Input({ transform: booleanAttribute }) showBackButton: boolean = false;
  @Input() backUrl: string = '';

  destroyRef = inject(DestroyRef);
  router = inject(Router);
  location = inject(Location);

  onBack() {
    if (this.backUrl) {
      this.router.navigateByUrl(this.backUrl);
    } else {
      this.location.back();
    }
  }
}
