import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, finalize, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { CoreModel, UnifiedUserInfo } from '../domain';
import { CoreState } from './states';
import { inject } from '@angular/core';
import { CoreApiService, ServiceFactory } from '../services/apis';
import { QueryOptions, WhereCondition } from '../services/types';
import { Auth } from '@angular/fire/auth';
import { parseMapId, parseUserId } from '../shared';
import { Map } from '../domain/models/map.model';

export abstract class CoreStore<
  T extends CoreModel,
  Y extends CoreState<T>,
  S extends CoreApiService<T>,
> extends ComponentStore<Y> {
  protected serviceFactory = inject(ServiceFactory);
  protected abstract serviceType: new (...args: any[]) => S;

  protected get service(): S {
    return this.serviceFactory.getService(this.serviceType);
  }

  protected auth = inject(Auth);

  constructor(initialState: Y) {
    super(initialState);
  }

  //Selectors
  readonly entities$ = this.select((state) => state.entities);
  readonly loading$ = this.select((state) => state.loading);
  readonly error$ = this.select((state) => state.error);
  readonly selectedEntity$ = this.select((state) => state.selectedEntity);

  readonly entities = this.selectSignal((state) => state.entities);
  readonly loading = this.selectSignal((state) => state.loading);
  readonly error = this.selectSignal((state) => state.error);
  readonly selectedEntity = this.selectSignal((state) => state.selectedEntity);

  readonly setClearSelectedEntity = this.updater((state) => ({
    ...state,
    selectedEntity: undefined,
  }));

  readonly setClearStore = this.updater((state) => ({
    ...state,
    entities: [],
    loading: false,
    error: undefined,
    selectedEntity: undefined,
  }));

  // State updaters
  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading,
  }));

  readonly setEntities = this.updater((state, entities: T[]) => ({
    ...state,
    entities,
  }));

  readonly setError = this.updater((state, error: string) => ({
    ...state,
    error,
  }));

  readonly setSelectedEntity = this.updater((state, entity: T) => ({
    ...state,
    selectedEntity: entity,
  }));

  readonly setNotFound = this.updater((state) => ({
    ...state,
    selectedEntity: undefined,
  }));

  // Effects
  readonly loadEntities = this.effect<{
    options?: QueryOptions;
    useCache?: boolean;
    callback?: (data: T[]) => void;
  }>((params$) => {
    return params$.pipe(
      //distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      withLatestFrom(this.entities$),
      switchMap(([params, entities]) => {
        // Check if we should use the cache
        if (entities.length > 0 && (params.useCache === undefined || params.useCache === true)) {
          if (params.options && params.options.where) {
            const filteredEntities = this.filterEntities(entities, params.options.where);
            if (params.callback) {
              params.callback(filteredEntities);
            }
            return EMPTY;
          }
          if (params.callback) {
            params.callback(entities);
          }
          return EMPTY;
        }

        // If not using cache, fetch from the API
        this.patchState({ loading: true, error: undefined } as Partial<Y>);
        return this.service.getAll(params.options ?? {}).pipe(
          tap({
            next: (response) => {
              this.patchState({ entities: response, loading: false } as Partial<Y>);
              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: Error) => {
              this.patchState({ error: error.message, loading: false } as Partial<Y>);
            },
          }),
          catchError((error: Error) => {
            console.error('Caught error:', error);
            this.patchState({ error: error.message, loading: false } as Partial<Y>);
            return of([]);
          }),
        );
      }),
      finalize(() => {
        this.patchState({ loading: false } as Partial<Y>);
      }),
    );
  });

  readonly loadEntity = this.effect<{ id: string; callback?: (data: T | null) => void }>((params$) =>
    params$.pipe(
      withLatestFrom(this.select((state) => state.entities)),
      switchMap(([params, entities]) => {
        if (!params.id) {
          this.setNotFound();
          return EMPTY;
        }

        const entity = entities.find((entity) => entity.id === params.id);
        if (entity) {
          if (params.callback) {
            params.callback(entity);
          }
          return of(entity);
        }
        this.setLoading(true);
        return this.service.get(params.id).pipe(
          tap({
            next: (entity) => {
              if (entity) this.setSelectedEntity(entity);
              if (params.callback) {
                params.callback(entity);
              }
              this.setLoading(false);
            },
            error: (error: any) => {
              this.setError(error.message);
              this.setLoading(false);
              if (params.callback) {
                params.callback(null);
              }
            },
          }),
          catchError((error) => {
            this.setError(error.message);
            if (params.callback) {
              params.callback(null);
            }
            return of(null);
          }),
          finalize(() => this.setLoading(false)),
        );
      }),
    ),
  );

  readonly createEntity = this.effect<{
    entity: T | Partial<T>;
    callback?: (data: T) => void;
  }>((params$) =>
    params$.pipe(
      switchMap((params) => {
        this.setLoading(true);
        return this.service.create(params.entity as T).pipe(
          tap({
            next: (response) => {
              this.updater((state) => ({
                ...state,
                entities: [...state.entities, response],
              }))();
              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: any) => {
              console.error('Error creating entity:', error);
              this.setError(error.message);
            },
          }),
          finalize(() => {
            this.setLoading(false);
          }),
          catchError((error) => {
            console.error('Caught error:', error);
            this.setError(error.message);
            return of([]);
          }),
        );
      }),
    ),
  );

  readonly updateEntity = this.effect<{
    id: string;
    entity: T | Partial<T>;
    callback?: (data: T) => void;
  }>((params$) =>
    params$.pipe(
      switchMap((params) => {
        this.updater((state) => ({
          ...state,
          entities: state.entities.map((entity) => (entity.id === params.id ? params.entity : entity)),
        }))();
        this.setLoading(true);
        return this.service.update(params.id, params.entity as T).pipe(
          tap({
            next: (response) => {
              this.setLoading(false);
              this.updater((state) => ({
                ...state,
                entities: state.entities.map((entity) => (entity.id === response.id ? response : entity)),
              }))();
              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: any) => {
              this.setError(error.message);
            },
          }),
          catchError((error) => {
            this.setError(error.message);
            return of([]);
          }),
        );
      }),
    ),
  );

  readonly deleteEntity = this.effect<{ id: string; callback?: (entities: T[]) => void }>((params$) =>
    params$.pipe(
      switchMap((params) => {
        this.setLoading(true);
        return this.service.delete(params.id).pipe(
          tap({
            next: () => {
              this.setLoading(false);
              this.updater((state) => ({
                ...state,
                entities: state.entities.filter((entity) => entity.id !== params.id),
              }))();

              if (params.callback) {
                params.callback(this.get().entities);
              }
            },
            error: (error: any) => {
              this.setError(error.message);
            },
          }),
          catchError((error) => {
            this.setError(error.message);
            if (params.callback) {
              params.callback(this.get().entities);
            }
            return of([]);
          }),
        );
      }),
    ),
  );

  private filterEntities(entities: T[], where: WhereCondition[]): T[] {
    return entities.filter((entity) =>
      where.every(({ field, operator, value }) => {
        const entityValue = entity[field as keyof T];
        return this.applyOperator(field, entityValue, operator, value);
      }),
    );
  }

  private applyOperator(
    field: string,
    entityValue: any,
    operator: WhereCondition['operator'],
    value: WhereCondition['value'], // Update the type of 'value' to include 'null'
  ): boolean {
    // Normalize entityValue if it's an object
    if (typeof entityValue === 'object' && entityValue !== null) {
      if (field.toLowerCase().includes('map')) {
        entityValue = parseMapId(entityValue);
      } else if (field === 'userId') {
        entityValue = parseUserId(entityValue);
      }
    }

    // Handle cases where value might be null
    if (value === null) {
      switch (operator) {
        case '==':
          return entityValue === null;
        case '!=':
          return entityValue !== null;
        default:
          throw new Error(`Unsupported operator for null value: ${operator}`);
      }
    }

    // Handle operators with explicit type guards
    switch (operator) {
      case '==':
        return entityValue === value;
      case '!=':
        return entityValue !== value;
      case '<':
        return typeof entityValue === 'number' && typeof value === 'number' && entityValue < value;
      case '<=':
        return typeof entityValue === 'number' && typeof value === 'number' && entityValue <= value;
      case '>':
        return typeof entityValue === 'number' && typeof value === 'number' && entityValue > value;
      case '>=':
        return typeof entityValue === 'number' && typeof value === 'number' && entityValue >= value;
      case 'array-contains':
        return Array.isArray(entityValue) && entityValue.includes(value);
      case 'in':
        return Array.isArray(value) && this.isInArray(value, entityValue);
      case 'array-contains-any':
        return Array.isArray(entityValue) && Array.isArray(value) && value.some((v) => entityValue.includes(v));
      case 'not-in':
        return Array.isArray(value) && !this.isInArray(value, entityValue);
      default:
        throw new Error(`Unsupported operator: ${operator}`);
    }
  }

  private isInArray(array: (string | number | boolean)[], value: string | number | boolean | null): boolean {
    if (value === null) return false; // null can't be in an array of primitives
    return array.includes(value);
  }
}
