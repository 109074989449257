import { signal, WritableSignal } from '@angular/core';
import { LocalStorageKey } from '../types';
import { Country, PeopleGroup } from 'src/app/domain';

export function localStorageSignal<T>(initialValue: T, localStorageKey: LocalStorageKey): WritableSignal<T> {
  const value = localStorage.getItem(localStorageKey);

  if (value) {
    try {
      initialValue = JSON.parse(value);
    } catch (e) {
      console.error('Failed to parsed stored for the key', localStorageKey, e);
    }
  } else {
    localStorage.setItem(localStorageKey, JSON.stringify(initialValue));
  }

  const writableSignal = signal(initialValue);

  const setter = writableSignal.set;
  writableSignal.set = (newValue: T) => {
    setter(newValue);
    localStorage.setItem(localStorageKey, JSON.stringify(newValue));
  };
  return writableSignal;
}
//usage
export const isUserLoggedInSignal = localStorageSignal<boolean>(false, 'IsUserLoggedIn');
export const isLockedSignal = localStorageSignal<boolean>(false, 'IsLocked');
export const peopleGroupSearchSignal = localStorageSignal<{
  country: Country | null;
  peopleGroup: PeopleGroup | null;
  search: string | null;
}>(
  {
    country: null,
    peopleGroup: null,
    search: null,
  },
  'PeopleGroupSearch',
);
