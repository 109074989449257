import { Injectable } from '@angular/core';
import { CoreStore } from './core.store';
import { MapConnection, Map } from '../domain';
import { MapConnectionState } from './states';
import { MapConnectionAPIService } from '../services/apis';
import { EMPTY, from, of, switchMap, tap } from 'rxjs';
import { generateUniqueRedeemCode, MapRoleType, parseMapId } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class MapConnectionStore extends CoreStore<MapConnection, MapConnectionState, MapConnectionAPIService> {
  override serviceType = MapConnectionAPIService;
  constructor() {
    super({ entities: [], loading: false, error: '', selectedEntity: undefined });
  }

  readonly splitOffStream = this.effect<{
    upstreamMapId: string;
    upstreamNodeId: string;
    callback?: (entity: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({ error: '', loading: true });
      }),
      switchMap((params) => {
        const newCode = generateUniqueRedeemCode('SPLIT_OFF');
        const newEntity: MapConnection = {
          upstreamMapId: parseMapId(params.upstreamMapId),
          upstreamNodeId: parseMapId(params.upstreamNodeId),
          downstreamMapId: '',
          redeemCode: newCode,
          role: 'admin' as MapRoleType,
        };

        return of(this.createEntity({ entity: newEntity, callback: params.callback }));
      }),
    ),
  );

  readonly joinChildMap = this.effect<{
    upstreamMapId: string;
    upstreamNodeId: string;
    callback?: (entity: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({ error: '', loading: true });
      }),
      switchMap((params) => {
        const newCode = generateUniqueRedeemCode('JOIN');
        const newEntity: MapConnection = {
          upstreamMapId: parseMapId(params.upstreamMapId),
          upstreamNodeId: parseMapId(params.upstreamNodeId),
          downstreamMapId: '',
          redeemCode: newCode,
          role: 'admin' as MapRoleType,
        };
        return of(this.createEntity({ entity: newEntity, callback: params.callback }));
      }),
    ),
  );

  readonly redeemJoinChildMap = this.effect<{
    redeemCode: string;
    downstreamMapId: string;
    callback?: (entity: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({ error: '', loading: true });
      }),
      switchMap((params) => {
        return from(this.getByRedeemCode(params.redeemCode)).pipe(
          switchMap((entity) => {
            if (entity) {
              entity.downstreamMapId = parseMapId(params.downstreamMapId);
              entity.upstreamMapId = parseMapId(entity.upstreamMapId);

              return of(this.updateEntity({ id: entity.id!, entity, callback: params.callback }));
            } else {
              return EMPTY;
            }
          }),
        );
      }),
    ),
  );

  readonly upstream = this.effect<{
    downstreamMapId: string;
    callback?: (entity: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({ error: '', loading: true });
      }),
      switchMap((params) => {
        const newCode = generateUniqueRedeemCode('UPSTREAM');
        const downstreamMapId = parseMapId(params.downstreamMapId);
        const newEntity: MapConnection = {
          downstreamMapId: downstreamMapId,
          upstreamNodeId: '',
          upstreamMapId: '',
          redeemCode: newCode,
          role: 'admin' as MapRoleType,
        };

        return of(this.createEntity({ entity: newEntity, callback: params.callback }));
      }),
    ),
  );

  readonly redeemJoinUpStream = this.effect<{
    redeemCode: string;
    upstreamMapId: string;
    upstreamNodeId: string;
    callback?: (entity: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => {
        this.patchState({ error: '', loading: true });
      }),
      switchMap((params) => {
        return from(this.getByRedeemCode(params.redeemCode)).pipe(
          switchMap((entity) => {
            if (entity) {
              entity.downstreamMapId = parseMapId(entity.downstreamMapId);
              entity.upstreamMapId = parseMapId(params.upstreamMapId);
              entity.upstreamNodeId = params.upstreamNodeId;
              entity.redeemedBy = this.service.getUserId()!;
              entity.redeemedAt = new Date().toISOString();
              return of(this.updateEntity({ id: entity.id!, entity, callback: params.callback }));
            } else {
              return EMPTY;
            }
          }),
        );
      }),
    ),
  );

  getByRedeemCode(redeemCode: string) {
    return this.service.getByRedeemCode(redeemCode);
  }
}
