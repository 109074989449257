import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonSpinner, IonRippleEffect } from '@ionic/angular/standalone';

@Component({
  selector: 'app-loading-button',
  template: `
    <button
      [class]="class"
      type="button"
      data-ripple-light="true"
      [disabled]="disabled || loading"
      (click)="callback()">
      <ion-ripple-effect></ion-ripple-effect>

      {{ text }}
      @if (loading) {
        <ion-spinner></ion-spinner>
      }
    </button>
  `,
  standalone: true,
  imports: [IonSpinner, IonRippleEffect],
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
})
export class LoadingButtonComponent {
  @Input() class: string = 'dark-button disabled:opacity-50 disabled:cursor-not-allowed';
  @Input({ required: true }) text: string = '';
  @Input({ required: true }) loading: boolean = false;
  @Input() disabled: boolean = false;

  @Output() CallBack = new EventEmitter<void>();

  callback() {
    this.CallBack.emit();
  }
}
