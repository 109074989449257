import { Template, Map, Node, Organization, DefaultMapLocation } from 'src/app/domain';

/**
 * Helper function to generate a title based on the template and map data.
 * @param template - The template containing render_settings.
 * @param mapData - The map data containing the fields to be included in the title.
 * @returns The generated title string.
 */
export function generateTitle(template: Template, mapData: Node): string {
  if (!template) return '';
  const renderSettings = template.render_settings;
  let title = renderSettings.list_title;

  // Find all placeholders in the list_title string
  const placeholders = title.match(/{{\s*[\w\.]+\s*}}/g) || [];

  placeholders.forEach((placeholder) => {
    // Extract the field name from the placeholder
    const fieldName = placeholder.replace(/[{}]/g, '').trim();

    // Get the value from mapData
    const value = fieldName === 'location' ? parseLocation(mapData[fieldName]) : mapData[fieldName];

    // Replace the placeholder with the actual value or an empty string if the value is null or undefined
    title = title.replace(placeholder, value !== null && value !== undefined ? String(value) : '');
  });

  // Return the generated title
  return title;
}

export function generateAddGatheringTitle(params: {
  template: Template;
  organization: Organization;
  nodeData?: Node | null;
  map?: Map | null;
}): string {
  const organizationType = params.organization.is_private ? 'Private' : 'Public';

  let suffix = organizationType === 'Private' ? 'Gathering' : 'Idea';

  if (params.nodeData) {
    const title = generateTitle(params.template, params.nodeData);
    return `Exit ${suffix} - ${title}`;
  }
  return `Add ${suffix} - ${params.organization.name}`;
}

export function parseLocation(value: DefaultMapLocation | string): string {
  const location = value as DefaultMapLocation | string;

  if (typeof location === 'string') {
    return location;
  }
  if (!location) {
    return '';
  }

  const fields = [
    location.location,
    location.level4?.name,
    location.level3?.name,
    location.level2?.name,
    location.level1?.name,
    location.country?.name,
  ];

  const selectedText = fields.find((field) => field && field !== '');
  return selectedText || '';
}
