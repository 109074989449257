import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  signal,
} from '@angular/core';
import { lastValueFrom, map, Observable, tap } from 'rxjs';
import { DefaultMapLocation, Location } from 'src/app/domain';
import { environment } from 'src/environments/environment';
import { InputComponent } from './input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ProgressBarModule } from 'primeng/progressbar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ModalController } from '@ionic/angular/standalone';

export interface LocationAPIResponse {
  rows: Location[];
  valid: boolean;
  label: {
    raw: string;
    full: string;
  };
}

@Component({
  selector: 'app-location-selector-modal',
  template: `<div class="flex flex-col w-full px-4 py-8">
    @if (loading()) {
      <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
    }
    <div class="flex flex-row items-center justify-between w-full">
      <div class="text-blue text-base font-normal" (click)="cancel()">Cancel</div>
      <button
        class="text-blue text-base font-normal disabled:text-primary-300 disabled:cursor-not-allowed"
        [disabled]="form.invalid || loading()"
        (click)="submit()">
        Next
      </button>
    </div>
    <form [formGroup]="form" class="flex flex-col items-center w-full gap-4 mt-4">
      <app-input label="Type a location ..." type="text" formControlName="location" customClass="bg-white"></app-input>
      <div class="w-full flex flex-row items-center justify-center p-4">
        <h3 class="text-primary-600 font-bold">OR</h3>
      </div>
      @for (level of levels; track $index) {
        @if (level.items.length > 0) {
          <p-floatLabel class="w-full">
            <p-autoComplete
              [formControlName]="level.name"
              [dropdown]="true"
              [suggestions]="level.filteredItems"
              [virtualScrollItemSize]="34"
              (completeMethod)="filterItems($event, level)"
              field="name"
              (onSelect)="levelChange($event, level)"
              [autofocus]="true"></p-autoComplete>
            <label for="float-label">{{ level.label }}</label>
          </p-floatLabel>
        }
      }
    </form>
  </div>`,
  standalone: true,
  imports: [InputComponent, AutoCompleteModule, ReactiveFormsModule, ProgressBarModule, FloatLabelModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectorModalComponent implements AfterViewInit {
  http = inject(HttpClient);
  cdr = inject(ChangeDetectorRef);
  modalCtrl = inject(ModalController);

  @Input() location: DefaultMapLocation | null = null;

  baseUrl = `${environment.backendUrl}/location/get-location`;

  countries$: Observable<Location[]> = this.http.get<LocationAPIResponse>(`${this.baseUrl}/0/parent_code`).pipe(
    tap((res) => {
      const label = res?.label?.full;
      this.levels[0].label = label || this.levels[0].label;
    }),
    map((res) => res.rows),
  );

  levels = [
    { name: 'country', label: 'Select Country', items: [] as Location[], filteredItems: [] as Location[] },
    { name: 'level1', label: 'Select District', items: [] as Location[], filteredItems: [] as Location[] },
    { name: 'level2', label: 'Select City', items: [] as Location[], filteredItems: [] as Location[] },
    { name: 'level3', label: 'Select Area', items: [] as Location[], filteredItems: [] as Location[] },
    { name: 'level4', label: 'Select Sub Area', items: [] as Location[], filteredItems: [] as Location[] },
  ];

  loading = signal(false);

  form = new FormGroup({
    location: new FormControl(''),
    country: new FormControl<Location | null>(null),
    level1: new FormControl<Location | null>(null),
    level2: new FormControl<Location | null>(null),
    level3: new FormControl<Location | null>(null),
    level4: new FormControl<Location | null>(null),
  });

  async ngAfterViewInit(): Promise<void> {
    this.loading.set(true);

    if (navigator.onLine) {
      this.levels[0].items = await lastValueFrom(this.countries$);
    }

    if (this.location) {
      await this.prepopulateLevels();
      this.form.patchValue({ ...this.location });
    }

    this.loading.set(false);
    this.cdr.markForCheck();
  }

  async prepopulateLevels(): Promise<void> {
    if (this.location) {
      const location = this.location;
      for (let i = 1; i <= 4; i++) {
        const levelKey = `level${i - 1}` as keyof DefaultMapLocation;
        const parentCode =
          typeof location[levelKey] !== 'string' ? (location[levelKey] as Location)?.code : location.country?.code;
        if (parentCode) {
          if (navigator.onLine) {
            await this.fetchSubLevel(i, parentCode);
          } else {
            this.populateOffline(i, location);
          }
        }
      }
    }
  }

  populateOffline(level: number, location: DefaultMapLocation) {
    const levelKey = `level${level}` as keyof DefaultMapLocation;
    const levelValue = location[levelKey];
    if (levelValue && typeof levelValue !== 'string') {
      this.levels[level].items = [levelValue];
    }
  }

  async fetchSubLevel(level: number, parentCode: string) {
    this.loading.set(true);
    const response = await lastValueFrom(
      this.http.get<LocationAPIResponse>(`${this.baseUrl}/${level}/${parentCode}`).pipe(
        tap((res) => {
          const label = res?.label?.full;
          this.levels[level].label = label || this.levels[level].label;
        }),
        map((res) => res.rows),
      ),
    );
    this.levels[level].items = response;
    this.loading.set(false);
    this.cdr.markForCheck();
  }

  levelChange(event: AutoCompleteSelectEvent, level: any) {
    const nextLevelIndex = this.levels.indexOf(level) + 1;
    if (nextLevelIndex < this.levels.length) {
      this.resetLevelsFrom(nextLevelIndex);
      if (navigator.onLine) {
        this.fetchSubLevel(nextLevelIndex, event.value.code);
      } else {
        this.populateOffline(nextLevelIndex, this.form.value as DefaultMapLocation);
      }
    }
  }

  resetLevelsFrom(index: number) {
    for (let i = index; i < this.levels.length; i++) {
      this.levels[i].items = [];
      this.levels[i].filteredItems = [];
      this.form.get(this.levels[i].name)?.setValue(null);
    }
  }

  filterItems(event: AutoCompleteCompleteEvent, level: any) {
    const query = event.query.toLowerCase();
    level.filteredItems = level.items.filter((item: Location) => item.name.toLowerCase().startsWith(query));
  }

  async cancel() {
    await this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    if (this.form.valid) {
      await this.modalCtrl.dismiss(this.form.value, 'submit');
    }
  }
}
