import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class FirebaseErrorHandler implements ErrorHandler {
  auth = inject(Auth);

  constructor(
    private firestore: Firestore,
    private ngZone: NgZone,
  ) {}

  handleError(error: any) {
    console.log('Global error caught by FirebaseErrorHandler:', error);
    const platform = Capacitor.getPlatform();
    // Ensure Firestore operations are conducted inside Angular's zone to maintain proper execution context
    this.ngZone.run(async () => {
      const currentUser = this.auth.currentUser ?? {};
      const errorLog = {
        message: error.message || null,
        stack: error.stack || null,
        timestamp: new Date(),
        platform,
        user: currentUser ?? 'anonymous user',
      };
      const errorsCollection = collection(this.firestore, 'errors');

      // // Log the error to Firestore
      addDoc(errorsCollection, errorLog).catch(console.error);
    });
  }
}
