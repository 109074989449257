import { Field } from 'src/app/domain';
import { Node, TreeNodeMapCollection, Map as MapCollection } from 'src/app/domain/models/map.model';
interface NumericStats {
  sum: number;
  average: number;
  percentage: number;
}

interface ToggleStats {
  on_count: number;
  middle_count: number;
  off_count: number;
  on_percentage: number;
  middle_percentage: number;
  off_percentage: number;
}

export interface AggregatedStats {
  max_generation: number;
  total_gathering: number;
  total_group: number;
  total_church: number;
  [key: string]: number | NumericStats | ToggleStats;
}

function buildHierarchy(items: Node[]): { rootNodes: Node[]; hierarchy: Map<string, Node[]> } {
  const hierarchy = new Map<string, Node[]>();
  const allNodesMap = new Map<string, Node>();

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    allNodesMap.set(item.id!, item);
    if (!hierarchy.has(item.id!)) {
      hierarchy.set(item.id!, []);
    }
  }

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.parentId) {
      if (!hierarchy.has(item.parentId)) {
        hierarchy.set(item.parentId, []);
      }
      hierarchy.get(item.parentId)!.push(item);
    }
  }

  const rootNodes = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item.parentId || !allNodesMap.has(item.parentId)) {
      rootNodes.push(item);
    }
  }

  return { rootNodes, hierarchy };
}

export function aggregateTreeStats(items: Node[], fields: Field[]): AggregatedStats {
  const { rootNodes, hierarchy } = buildHierarchy(items);

  const numericStats: {
    [key: string]: { sum: number; count: number };
  } = {};

  const toggleStats: {
    [key: string]: { onCount: number; middleCount: number; offCount: number; count: number };
  } = {};

  let total_church = 0;
  let total_group = 0;

  if (!fields) return {} as AggregatedStats;

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.include_in_stats) {
      if (field.type === 'numeric') {
        numericStats[field.name] = { sum: 0, count: 0 };
      } else if (field.type === 'toggle') {
        toggleStats[field.name] = { onCount: 0, middleCount: 0, offCount: 0, count: 0 };
      }
    }
  }

  let max_generation = 0;
  let total_gathering = 0;

  function traverse(node: Node, currentDepth = 1): void {
    total_gathering++;
    max_generation = Math.max(max_generation, currentDepth);

    if (node.group_or_church === 'church') {
      total_church++;
    } else if (node.group_or_church === 'group') {
      total_group++;
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const key = field.name;
      const value = node[key];

      if (field.type === 'numeric') {
        numericStats[key].sum += (value as number) || 0;
        numericStats[key].count += 1;
      } else if (field.type === 'toggle') {
        toggleStats[key].count += 1;
        if (value === 'on') {
          toggleStats[key].onCount += 1;
        } else if (value === 'middle') {
          toggleStats[key].middleCount += 1;
        } else if (value === 'off' || value === undefined) {
          toggleStats[key].offCount += 1;
        }
      }
    }

    const children = hierarchy.get(node.id!) || [];
    for (let i = 0; i < children.length; i++) {
      traverse(children[i], currentDepth + 1);
    }
  }

  for (let i = 0; i < rootNodes.length; i++) {
    traverse(rootNodes[i]);
  }

  const aggregatedStats: AggregatedStats = { max_generation, total_gathering, total_church, total_group };

  for (const key in numericStats) {
    const sum = numericStats[key].sum;
    const count = numericStats[key].count;
    aggregatedStats[key] = {
      sum,
      average: Math.round(count ? sum / count : 0),
      percentage: total_gathering ? Math.round((sum / total_gathering) * 100) : 0,
    };
  }

  for (const key in toggleStats) {
    const { onCount, middleCount, offCount, count } = toggleStats[key];
    aggregatedStats[key] = {
      on_count: onCount,
      middle_count: middleCount,
      off_count: offCount,
      on_percentage: total_gathering ? Math.round((onCount / total_gathering) * 100) : 0,
      middle_percentage: total_gathering ? Math.round((middleCount / total_gathering) * 100) : 0,
      off_percentage: total_gathering ? Math.round((offCount / total_gathering) * 100) : 0,
    };
  }

  const attendees_count = (aggregatedStats['attendees'] as NumericStats)?.sum || 0;
  if (attendees_count > 0) {
    const repented_count = (aggregatedStats['repented'] as NumericStats)?.sum || 0;
    const dunked_count = (aggregatedStats['dunked'] as NumericStats)?.sum || 0;

    const repented_percentage = attendees_count ? Math.round((repented_count / attendees_count) * 100) : 0;
    const dunked_percentage = repented_count ? Math.round((dunked_count / repented_count) * 100) : 0;

    (aggregatedStats['repented'] as NumericStats).percentage = repented_percentage;
    (aggregatedStats['dunked'] as NumericStats).percentage = dunked_percentage;
  }

  return aggregatedStats;
}
// Function 1: Get node with all its descendants as a flat array
export function getNodeWithDescendants(nodes: Node[], nodeId: string): Node[] {
  const result: Node[] = [];
  const targetNode = nodes.find((n) => n.id === nodeId);
  if (!targetNode) return result;

  const addNodeAndDescendants = (id: string) => {
    const node = nodes.find((n) => n.id === id);
    if (node) {
      result.push(node);
      nodes.filter((n) => n.parentId === id).forEach((child) => addNodeAndDescendants(child.id!));
    }
  };

  addNodeAndDescendants(nodeId);
  return result;
}

// Function 2: Get node with its ancestors and all descendants as a flat array
export function getNodeFamilyTree(nodes: Node[], nodeId: string, childOnly: string): Node[] {
  const result: Node[] = [];
  const targetNode = nodes.find((n) => n.id === nodeId);
  if (!targetNode) return result;

  const addAncestors = (id: string | undefined) => {
    if (!id) return;
    const node = nodes.find((n) => n.id === id);
    if (node) {
      addAncestors(node.parentId);
      result.push(node); // Add ancestors to the end of the array to maintain order from root to leaf
    }
  };

  const addNodeAndDescendants = (id: string) => {
    const node = nodes.find((n) => n.id === id);
    if (node) {
      if (!result.some((n) => n.id === node.id)) {
        result.push(node);
      }
      nodes.filter((n) => n.parentId === id).forEach((child) => addNodeAndDescendants(child.id!));
    }
  };

  if (childOnly === 'false') {
    addAncestors(targetNode.parentId);
    addNodeAndDescendants(nodeId);
  } else {
    addNodeAndDescendants(nodeId);
  }

  return result;
}

// Helper functions
export function getNodeWithDescendantsFromMap(map: MapCollection, nodeId: string): Node[] {
  if (!map || !map.nodes) return [];
  return getNodeWithDescendants(map.nodes, nodeId);
}

export function getNodeFamilyTreeFromMap(map: MapCollection, nodeId: string, childOnly: string): Node[] {
  return getNodeFamilyTree(map.nodes, nodeId, childOnly);
}

export function validateAndFixTree(data: Node[]): Node[] {
  const nodeMap = new Map(data.map((node) => [node.id, node]));
  const visited = new Set<string>();
  const recursionStack = new Set<string>();

  function dfs(nodeId: string): boolean {
    if (!nodeMap.has(nodeId)) return false;
    if (recursionStack.has(nodeId)) return true;
    if (visited.has(nodeId)) return false;

    visited.add(nodeId);
    recursionStack.add(nodeId);

    const node = nodeMap.get(nodeId)!;
    if (node.parentId && dfs(node.parentId)) {
      // Cycle detected, make this node a root
      node.parentId = '';
    }

    recursionStack.delete(nodeId);
    return false;
  }

  // Start DFS from each unvisited node
  data.forEach((node) => {
    if (!visited.has(node.id!)) {
      dfs(node.id!);
    }
  });

  return data;
}
