import { Injectable } from '@angular/core';
import { Organization } from 'src/app/domain';
import { CoreApiService } from './core.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';
import { Observable, from, switchMap, map, catchError, of, throwError } from 'rxjs';
import { QueryOptions } from '../types';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAPIService extends CoreApiService<Organization> {
  protected override dbPath: string = 'organizations';
  protected getServiceIdentifier(): string {
    return 'OrganizationApiService';
  }
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<Organization>) {
    super(http, firebaseApp, dataService);
  }

  override getAll(queryOptions: QueryOptions): Observable<Organization[]> {
    return this.dataService.getAllLocalData<Organization>(this.dbPath).pipe(
      switchMap((data) => {
        if (data && data.length > 0) {
          return of(data);
        } else {
          queryOptions.where = [
            {
              field: 'name',
              operator: '!=',
              value: '',
            },
          ];
          return super.getAll(queryOptions);
        }
      }),
    );
  }
}
