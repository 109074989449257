import { Map, UnifiedUserInfo } from 'src/app/domain';

export const parseMapId = (mapId: string | Map): string => {
  if (!mapId) return '';
  return typeof mapId === 'string' ? mapId : mapId.id!;
};

export const parseUserId = (userId: string | UnifiedUserInfo): string => {
  return typeof userId === 'string' ? userId : userId.uid!;
};
