import { v4 as uuidv4 } from 'uuid';

export function generateUniqueId() {
  const id = uuidv4();
  return id;
}

export function generateUniqueRedeemCode(type: 'INVITE' | 'SPLIT_OFF' | 'UPSTREAM' | 'DOWNSTREAM' | 'JOIN'): string {
  // Get current timestamp
  const timestamp = Date.now().toString(36);

  // Generate a UUID
  const uuid = uuidv4().replace(/-/g, '');

  // Combine and hash
  const combined = timestamp + uuid;
  let hash = 0;
  for (let i = 0; i < combined.length; i++) {
    const char = combined.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Convert hash to base 36 and take the first 8 characters
  const code = Math.abs(hash).toString(36).substr(0, 8).toUpperCase();

  // Ensure the code is exactly 8 characters by padding if necessary
  return `${type}-${code.padEnd(8, 'A')}`;
}
