import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Map } from 'src/app/domain';

@Injectable({
  providedIn: 'root',
})
export class DocumentSnapshotListenerService {
  public mapSubject = new Subject<Map>();
}
