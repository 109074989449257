import { inject, Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { v4 as uuid } from 'uuid';
import { ApplicationInitializerService, DataService } from '..';
import { environment } from 'src/environments/environment';
import { Auth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private auth = inject(Auth);
  private socket!: Socket;

  private dataService = inject(DataService);
  private appInitializer = inject(ApplicationInitializerService);

  url = environment.backendUrl;

  connectionStatus = new BehaviorSubject<boolean>(false);

  constructor() {}

  initialize() {
    const token = uuid();
    const userId = this.auth.currentUser?.uid;
    if (!userId) return;

    const config: SocketIoConfig = {
      url: environment.backendUrl, // Replace with your WebSocket server URL
      options: {
        query: {
          token,
          userId,
        },
      },
    };

    this.socket = new Socket(config);

    // Listen for events after initialization
    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
      this.connectionStatus.next(true);
      this.appInitializer.appStateChangedNotifier.next();
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      this.connectionStatus.next(false);
    });

    this.onMessage()?.subscribe((message: any) => {
      console.log('Received message:', message);
      this.acknowledge(message.messageId, message.userId);
      const messageType = message.messageId;
      const data = message.message.data;

      switch (messageType) {
        case 'TEMPLATE_UPDATED':
          const endpoint = `${this.url}/templates/${data.id}`;
          this.dataService.putData('templates', endpoint, data.id, data);
          break;

        case 'MAP_UPDATED':
          const mapEndpoint = `${this.url}/maps/${data.id}`;
          this.dataService.putData('maps', mapEndpoint, data.id, data);
          this.appInitializer.mapUpdateNotifier.next(data.id);
          break;
        case 'MAP_DELETED':
          const mapDeleteEndpoint = `${this.url}/maps/${data.id}`;
          this.dataService.deleteData('maps', mapDeleteEndpoint, data.id);
          this.appInitializer.mapDeleteNotifier.next(data.id);
          break;
      }
    });

    this.onPublicMessage()?.subscribe((message) => {
      console.log('Received public message:', message);
    });

    // Add other event listeners as needed
  }

  connect() {
    if (this.socket) {
      this.socket.connect();
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  register(token: string, userId: string) {
    if (this.socket) {
      this.socket.emit('register', { token, userId });
    }
  }

  acknowledge(messageId: string, userId: string) {
    if (this.socket) {
      this.socket.emit('acknowledge', { messageId, userId });
    }

    return Promise.resolve();
  }

  onMessage() {
    return this.socket ? this.socket.fromEvent('message') : null;
  }

  onPublicMessage() {
    return this.socket ? this.socket.fromEvent('publicMessage') : null;
  }

  onDisconnect() {
    return this.socket ? this.socket.fromEvent('disconnect') : null;
  }
}
