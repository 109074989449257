import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserStore } from '../../store/user.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { ModalController } from '@ionic/angular/standalone';
import { PinComponent } from '../../features/auth/screens/pin.component';
import { isLockedSignal } from 'src/app/shared';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  userStore = inject(UserStore);
  user = toSignal(this.userStore.user$);

  private lastInteraction: number = Date.now();
  private idleLimit = 60000 * 5; // 5 minutes in milliseconds
  private idleCheckInterval = 10000; // Check every second
  private modalOn = new BehaviorSubject<boolean>(false); // Use BehaviorSubject
  private interval: any = null;
  private auth = inject(Auth);
  dismissModal = new Subject<void>();

  modalCtrl = inject(ModalController);

  constructor() {}

  startTracking() {
    this.updateLastInteraction();
    document.addEventListener('touchstart', this.updateLastInteraction.bind(this), true);
    document.addEventListener('click', this.updateLastInteraction.bind(this), true);

    this.interval = setInterval(() => {
      if (Date.now() - this.lastInteraction > this.idleLimit) {
        this.userIsIdle();
      }
    }, this.idleCheckInterval);
  }

  updateLastInteraction = () => {
    this.lastInteraction = Date.now();
  };

  async userIsIdle() {
    const modalOnValue = this.getModalOnValue();
    if (modalOnValue) return;

    const userId = await this.auth.currentUser?.uid;
    if (!userId) {
      return;
    }

    if (!this.user()) return;
    if (this.user()?.pin === '' || !this.user()?.activePin) return;

    this.modalOn.next(true); // Set modal state to on

    const modal = await this.modalCtrl.create({
      component: PinComponent,
      showBackdrop: false,
      backdropDismiss: false,
      componentProps: { isLooked: true },
    });

    isLockedSignal.set(true);
    await modal.present();
    await modal.onWillDismiss();
    this.dismissModal.next();
    this.modalOn.next(false); // Reset modal state upon dismissal
    isLockedSignal.set(false);

    // Restart the idle check interval if it was cleared
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (Date.now() - this.lastInteraction > this.idleLimit && !modalOnValue) {
          this.userIsIdle();
        }
      }, this.idleCheckInterval);
    }
  }

  handleAppStateChange({ isActive }: { isActive: boolean }) {
    if (!isActive) {
      this.userIsIdle();
    } else {
      this.updateLastInteraction();
    }
  }

  public get modalOn$() {
    return this.modalOn.asObservable();
  }

  getModalOnValue() {
    return this.modalOn.value;
  }

  setModalOff() {
    this.modalOn.next(false);
  }
}
