import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import {
  Organization,
  Profile,
  UnifiedUserInfo,
  Notification,
  Map,
  MenuItem,
  MapPermission,
  MapConnection,
  Template,
} from 'src/app/domain';
import { catchError, from, lastValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends CoreApiService<UnifiedUserInfo> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<UnifiedUserInfo>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'users';
  protected getServiceIdentifier(): string {
    return 'UserApiService';
  }

  userExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/${this.dbPath}/user-exists/${email}`);
  }

  sendOTP(email: string): Observable<string> {
    return this.http.post<string>(`${this.url}/${this.dbPath}/send-otp/${email}`, {});
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}/${this.dbPath}/forgot-password`, {
      email,
    });
  }

  getUserByEmail(email: string): Observable<UnifiedUserInfo> {
    return this.http.get<UnifiedUserInfo>(`${this.url}/${this.dbPath}/get-user-by-email/${email}`);
  }

  register(data: UnifiedUserInfo): Observable<UnifiedUserInfo> {
    return this.http.post<UnifiedUserInfo>(`${this.url}/${this.dbPath}/register`, data);
  }

  verifyOTP(email: string, otp: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}/${this.dbPath}/verify-otp`, {
      email,
      otp,
    });
  }

  validateOrganization(referralCode: string, email: string): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.url}/${this.dbPath}/validate-and-organization`, {
        referralCode,
        email,
      })
      .pipe(
        catchError((error) => {
          return of(false);
        }),
      );
  }

  skippedReferral(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}/${this.dbPath}/skipped-referral`, { email });
  }

  updatePassword(email: string, newPassword: string): Observable<{ status: boolean; result: string }> {
    return this.http.post<{ status: boolean; result: string }>(`${this.url}/email/updatePassword`, {
      email,
      newPassword,
    });
  }

  getProfile(): Observable<Profile> {
    const userId = this.auth.currentUser?.uid;
    if (!userId) throw new Error('User not logged in');

    const endpoint = `${this.url}/${this.dbPath}/get-profile/${userId}`;

    if (navigator.onLine) {
      return this.http.get<Profile>(endpoint).pipe(
        switchMap((profile) => {
          const dataMap = {
            users: [profile.user],
            organizations: profile.organizations,
            templates: profile.templates,
            notifications: profile.notifications,
            maps: profile.maps,
            map_permissions: profile.map_permissions,
            map_connections: profile.map_connections,
            menu_items: profile.menu_items,
          };

          // Clear existing data
          return this.dataService
            .clearMultipleData([
              'users',
              'organizations',
              'templates',
              'notifications',
              'maps',
              'map_permissions',
              'map_connections',
              'menu_items', // Fixed the collection name typo from 'menuItems' to 'menu_items'
            ])
            .pipe(
              switchMap(() => {
                // Save the new profile data
                return this.dataService.saveMultipleData(dataMap).pipe(map(() => profile));
              }),
            );
        }),
        catchError((error) => {
          console.error('Error fetching profile from server:', error);
          // Load profile from local storage on error
          return from(this.loadProfileFromLocal()).pipe(
            map((localData) => {
              if (localData) {
                return localData;
              } else {
                throw error;
              }
            }),
          );
        }),
      );
    } else {
      // Load profile from local storage if offline
      return from(this.loadProfileFromLocal());
    }
  }

  getAllUsers(search?: string): Observable<UnifiedUserInfo[]> {
    return this.http.get<UnifiedUserInfo[]>(`${this.url}/${this.dbPath}/get-all-users/${search}`);
  }

  findUsers(queryString: string): Observable<UnifiedUserInfo[]> {
    return this.http.get<UnifiedUserInfo[]>(`${this.url}/${this.dbPath}/find-users/${queryString}`);
  }

  getUsers(ids: string[]): Observable<UnifiedUserInfo[]> {
    return this.http.post<UnifiedUserInfo[]>(`${this.url}/${this.dbPath}/get-users`, { ids });
  }

  private async loadProfileFromLocal(): Promise<Profile> {
    const dataMap = await lastValueFrom(
      this.dataService.getMultipleData([
        'users',
        'organizations',
        'templates',
        'notifications',
        'maps',
        'map_permissions',
        'map_connections',
        'userType',
        'menuItems',
      ]),
    );
    console.log('load local data', dataMap);
    return {
      user: dataMap['users'][0] as UnifiedUserInfo,
      organizations: dataMap['organizations'][0] as Organization[],
      templates: dataMap['templates'] as Template[],
      notifications: dataMap['notifications'] as Notification[],
      maps: dataMap['maps'] as Map[],
      map_permissions: dataMap['map_permissions'] as MapPermission[],
      map_connections: dataMap['map_connections'] as MapConnection[],
      userType: dataMap['userType'][0] as 'public' | 'private',
      menu_items: dataMap['menu_items'] as MenuItem[],
    };
  }
}
