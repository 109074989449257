import { Injectable, Injector } from '@angular/core';
import { CoreApiService } from './core.service';
import { CoreModel } from 'src/app/domain';

@Injectable({
  providedIn: 'root',
})
export class ServiceFactory {
  private services: Map<string, CoreApiService<any>> = new Map();

  constructor(private injector: Injector) {}

  getService<T extends CoreModel, S extends CoreApiService<T>>(serviceType: new (...args: any[]) => S): S {
    const serviceName = serviceType.name;
    if (!this.services.has(serviceName)) {
      this.services.set(serviceName, this.injector.get(serviceType));
    }
    return this.services.get(serviceName) as S;
  }
}
