import { Pipe, PipeTransform } from '@angular/core';
import { Map, MapConnection, Node } from 'src/app/domain';
import { buildMapTree, buildTree } from '../helpers';

export interface MapTreeInput {
  maps: Map[];
  connections: MapConnection[];
}

@Pipe({
  name: 'tree',
  standalone: true,
})
export class TreePipe implements PipeTransform {
  transform(value: Node[], connection: MapConnection[]): Node[] {
    return buildTree(value, connection);
  }
}
@Pipe({
  name: 'mapTree',
  standalone: true,
})
export class MapTreePipe implements PipeTransform {
  transform(input: MapTreeInput): Map[] {
    return buildMapTree(input.maps, input.connections);
  }
}
