import { AfterViewInit, Component, effect, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputIconModule } from 'primeng/inputicon';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  ModalController,
} from '@ionic/angular/standalone';
import { Country } from 'src/app';

@Component({
  selector: 'app-country-selector-modal',
  template: `
    <ion-header>
      <ion-toolbar color="secondary">
        <ion-title>Select Country</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" (click)="onClose()">
            <img appFallbackImg src="assets/icons/svg/check.svg" alt="" width="24" height="24" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="flex flex-col items-center justify-start p-4">
        <form [formGroup]="form" class="w-full h-full">
          <div class="w-full h-12 rounded-md border border-primary-200 flex gap-2 items-center p-1">
            <p-inputIcon styleClass="pi pi-search" />
            <input type="text" pInputText placeholder="Search" class="w-full outline-none" formControlName="search" />
          </div>
          <div class="w-full flex flex-col items-start justify-start h-[calc(100%-3rem)] overflow-y-scroll mt-2">
            @for (country of filteredList(); track country.id) {
              <div class="flex flex-row items-center justify-start w-full px-2 py-4 border-b-2">
                <input type="radio" formControlName="selectedCountry" [value]="country.id" class="w-6 h-6" />
                <label [for]="country.id" class="ml-2" (click)="onSelect(country)">
                  {{ country.name }}
                </label>
              </div>
            }
          </div>
        </form>
      </div>
    </ion-content>
  `,
  standalone: true,
  imports: [
    InputIconModule,
    ReactiveFormsModule,
    RadioButtonModule,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
  ],
})
export class CountrySelectorModalComponent implements AfterViewInit {
  countries = input.required<Country[]>();
  selectedCountry = input<Country | null>();

  filteredList = signal<Country[]>([]);
  form: FormGroup = new FormGroup({
    search: new FormControl(''),
    selectedCountry: new FormControl<Country | null>(null),
  });

  modalCtrl = inject(ModalController);

  constructor() {
    effect(
      () => {
        this.filteredList.set(this.countries().sort((a, b) => a.name.localeCompare(b.name)));
      },
      { allowSignalWrites: true },
    );

    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.filteredList.set(
        this.countries().filter((country) => country.name.toLowerCase().includes(value.search.toLowerCase())),
      );
    });
  }
  ngAfterViewInit(): void {
    if (this.selectedCountry()) {
      this.form.patchValue({ search: '', selectedCountry: this.selectedCountry()?.id });
    }
  }

  onClose() {
    const countryId = this.form.get('selectedCountry')?.value ?? null;
    const data = this.countries().find((country) => country.id === countryId) ?? null;
    this.modalCtrl.dismiss(data);
  }

  onSelect(country: Country) {
    this.form.patchValue({ selectedCountry: country.id });
    this.onClose();
  }
}
