import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStore } from 'src/app/store';
import { ApplicationInitializerService, MapService } from 'src/app/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, distinctUntilChanged, interval, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular/standalone';
import { LoadingDotsComponent } from './loading-dot.component';
import { Template } from 'src/app/domain';

export interface InitialItem {
  item: string;
  status: 'idle' | 'loading' | 'loaded' | 'error';
}

@Component({
  selector: 'app-initial-data',
  template: `
    <div class="flex flex-col gap-2 w-full h-full p-8 items-center">
      <div class="flex items-center justify-center p-1 bg-blue-gray-100 rounded-full w-24 h-24">
        <img src="assets/icons/svg/rotate.svg" class="w-16 h-16" />
      </div>
      <p class="text-gray-800 text-base font-medium font-['Roboto'] leading-normal truncate mt-2">
        We are syncing your data.
      </p>
      <p class="text-gray-800 text-base font-medium font-['Roboto'] leading-normal truncate">It might take a while</p>
      <p class="text-gray-800 text-base font-medium font-['Roboto'] leading-normal truncate">Please wait</p>
      <div class="flex flex-col w-full items-start justify-start gap-2 mt-4">
        @for (item of items; track $index) {
          <div class="flex flex-row justify-start items-start gap-2 w-full border-b border-primary-50">
            <div class="w-full flex flex-row items-start justify-start gap-2 py-2">
              @switch (item.status) {
                @case ('idle') {
                  <img src="assets/icons/svg/circle-dashed.svg" class="w-8 h-8" />
                }
                @case ('loading') {
                  <img src="assets/icons/svg/clock-rotate-left.svg" class="w-8 h-8" />
                }
                @case ('loaded') {
                  <img src="assets/icons/svg/circle-check.svg" class="w-8 h-8" />
                }
                @case ('error') {
                  <img src="assets/icons/svg/circle-info.svg" class="w-8 h-8" />
                }
              }

              <p class="text-gray-800 text-base font-medium font-['Roboto'] leading-normal truncate">
                {{ item.item }}
                @if (item.status === 'loading') {
                  <app-loading-dots />
                }
              </p>
            </div>
          </div>
        }
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, LoadingDotsComponent],
})
export class InitialDataComponent implements OnInit {
  items: InitialItem[] = [
    { item: 'Loading Company', status: 'idle' },
    { item: 'Loading Profile', status: 'idle' },
    { item: 'Loading Maps', status: 'idle' },
    { item: 'Loading Templates', status: 'idle' },
    { item: 'Loading Countries', status: 'idle' },
    { item: 'Loading People Group', status: 'idle' },
  ];
  userStore = inject(UserStore);
  service = inject(MapService);
  initService = inject(ApplicationInitializerService);
  destroyRef = inject(DestroyRef);
  modalCtrl = inject(ModalController);

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    this.initService.initialize();
    this.userStore.getProfile();

    this.setupObservers();
    this.checkCompletion();
  }

  setupObservers() {
    this.observeStore<boolean, any>(
      this.service.organizationStore.loading$,
      this.service.organizationStore.entities$,
      0,
    );
    this.observeStore<boolean, any>(this.userStore.loading$, this.userStore.selectedEntity$, 1, (user) => {
      if (user) {
        this.service.organizationStore.loadEntity({
          id: user.organizationId!,
          callback: (entity) => {
            if (entity) {
              this.service.onSetCurrentOrganization(entity);
              this.service.onSetIsPrivate(entity.is_private);
              this.service.templateStore.setEntities(entity.templateIds as Template[]);
            }
          },
        });
      }
    });
    this.observeStore<boolean, any>(this.service.mapStore.loading$, this.service.mapStore.entities$, 2);
    this.observeStore<boolean, any>(this.service.templateStore.loading$, this.service.templateStore.entities$, 3);
    this.observeServiceSync(this.initService.countrySynced, 4);
    this.observeServiceSync(this.initService.peopleGroupsSynced, 5);

    combineLatest([
      this.initService.countrySynced,
      this.initService.peopleGroupsSynced,
      this.initService.everythingSynced,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(async ([countries, peopleGroups, everything]) => {
        if (countries && peopleGroups && everything) {
          await this.closeModal();
        }
      });
  }

  observeStore<T1, T2>(
    loading$: Observable<T1>,
    entities$: Observable<T2>,
    index: number,
    callback?: (entity: T2) => void,
  ) {
    combineLatest([loading$, entities$])
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(([loading, entities]) => {
        this.items[index].status = loading ? 'loading' : 'loaded';
        if (callback) {
          callback(entities);
        }
      });
  }

  observeServiceSync(sync$: Observable<boolean>, index: number) {
    sync$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((synced) => {
      this.items[index].status = synced ? 'loaded' : 'loading';
    });
  }

  checkCompletion() {
    interval(500)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async () => {
        const completed = this.items.every((item) => item.status === 'loaded');
        if (completed) {
          await this.closeModal();
        }
      });
  }

  async closeModal() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      this.modalCtrl.dismiss();
    }
  }
}
