import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { distinctUntilChanged } from 'rxjs';
import { PushNotification } from 'src/app/domain';
import { PushNotificationApiService, UserApiService } from 'src/app/services/apis';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationHelperService {
  pushNotificationService = inject(PushNotificationApiService);
  userService = inject(UserApiService);
  destroyRef = inject(DestroyRef);
  router = inject(Router);
  private currentToken: string | null = null;

  async registerPushNotification() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
    await this.addListeners();
  }

  async addListeners() {
    await PushNotifications.addListener('registration', (token) => {
      if (this.currentToken !== token.value) {
        this.currentToken = token.value;
        const model: PushNotification = {
          token: token.value,
          userId: this.pushNotificationService.getUserId()!,
          platform: Capacitor.getPlatform(),
        };
        this.pushNotificationService
          .create(model)
          .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
          .subscribe({
            next: () => {},
          });
      }
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      if (notification.data['id']) this.router.navigateByUrl('/notifications', notification.data['id']);
      else this.router.navigateByUrl('/notifications');
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }
}
