import { Injectable, Type, ComponentRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalComponentRef: ComponentRef<any> | null = null;
  private viewContainerRef: ViewContainerRef | null = null;

  setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }

  async show<T extends object>(component: Type<T>, params?: Partial<T>): Promise<any> {
    if (!this.viewContainerRef) {
      throw new Error('ViewContainerRef not set. Please set it in your component before using ModalService.');
    }

    // Ensure any previously created component is destroyed
    this.closeModal();

    // Directly use the component class to create the component
    this.modalComponentRef = this.viewContainerRef.createComponent(component);

    if (params && this.modalComponentRef) {
      Object.assign(this.modalComponentRef.instance, params);
    }

    if (this.modalComponentRef) {
      return new Promise((resolve) => {
        this.modalComponentRef!.instance.closeModal.subscribe((data: any) => {
          this.closeModal();
          resolve(data);
        });
      });
    } else {
      throw new Error('Failed to create modal component.');
    }
  }

  closeModal() {
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
      this.modalComponentRef = null;
    }
  }
}
