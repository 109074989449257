import { Component, forwardRef, inject, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { Country } from 'src/app';
import { CountrySelectorModalComponent } from './country-selector-modal.component';

@Component({
  selector: 'app-country-selector',
  template: `
    <div class="w-full h-12 rounded-md border border-primary-200 relative">
      <div class="absolute top-[-8px] left-2 bg-white leading-tight text-[11px] text-primary-400 px-2">
        Default Country
      </div>

      @if (!value) {
        <div class=" p-2 " (click)="openCountrySelectorModal()">Select Default Country</div>
      } @else {
        <div class="flex flex-row items-center justify-between w-full p-2" (click)="openCountrySelectorModal()">
          <div>{{ value!.name }}</div>
        </div>
      }
    </div>
  `,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectorComponent),
      multi: true,
    },
  ],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class CountrySelectorComponent implements ControlValueAccessor {
  modalController = inject(ModalController);

  countries = input.required<Country[]>();

  disabled = false;
  touched = false;
  onChange = (country: Country) => {};
  onTouched = () => {};
  value: Country | null = null;

  writeValue(value: Country): void {
    if (value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  async openCountrySelectorModal() {
    const selectedCountry = signal<Country | null>(this.value);
    const modal = await this.modalController.create({
      component: CountrySelectorModalComponent,
      componentProps: {
        countries: this.countries,
        selectedCountry,
      },
    });

    modal.onDidDismiss().then(({ data }) => {
      if (data) {
        this.onChange(data);
        this.value = data;
      }
    });

    return await modal.present();
  }
}
