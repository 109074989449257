export const environment = {
  production: true,
  version: '1.0.18',
  firebase: {
    apiKey: 'AIzaSyAnWZ3Xs2REM5CWVh1fCmS1ZHvh16yAthU',
    authDomain: 'chart-paper-production.firebaseapp.com',
    projectId: 'chart-paper-production',
    storageBucket: 'chart-paper-production.appspot.com',
    messagingSenderId: '615947349798',
    appId: '1:615947349798:web:f5bf46675e17ec4b459be7',
    measurementId: 'G-DKR18R0KBQ',
  },
  appUrl: 'https://chartpaper.app',
  backendUrl: 'https://chart-paper.chartpaper.app',
};
