import { Injectable } from '@angular/core';
import { MapConnection } from 'src/app/domain';
import { CoreApiService } from './core.service';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';
import { of, switchMap, take, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapConnectionAPIService extends CoreApiService<MapConnection> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<MapConnection>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'map_connections';
  protected getServiceIdentifier(): string {
    return 'MapConnectionApiService';
  }

  getByRedeemCode(redeemCode: string): Observable<MapConnection | null> {
    return this.getAll({
      where: [
        {
          field: 'redeemCode',
          operator: '==',
          value: redeemCode,
        },
      ],
    }).pipe(
      switchMap((connections: MapConnection[]) => {
        if (connections.length > 0) {
          return of(connections[0]);
        }
        return of(null);
      }),
    );
  }
}
