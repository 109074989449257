import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { PushNotification } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { DataService } from '..';
import { FirebaseApp } from '@angular/fire/app';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationApiService extends CoreApiService<PushNotification> {
  protected override dbPath: string = 'push_notifications';
  protected getServiceIdentifier(): string {
    return 'PushNotificationApiService';
  }
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<PushNotification>) {
    super(http, firebaseApp, dataService);
  }
}
