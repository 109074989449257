import { Pipe, PipeTransform } from '@angular/core';
import { DefaultMapLocation, PeopleGroup, Node } from 'src/app/domain';

@Pipe({
  name: 'gathering',
  standalone: true,
})
export class GatheringPipe implements PipeTransform {
  transform(key: string | undefined, item: Node): string {
    if (!key) return '';
    if (item && key in item) {
      switch (typeof item[key as keyof Node]) {
        case 'string':
          if (key === 'group_or_church') {
            const value = item[key as keyof Node]?.toString() ?? '';
            if (value === 'group') {
              return 'left-7';
            }

            if (value === 'church') {
              return 'left-24';
            }

            return 'group';
          }

          if (key === 'start_date') {
            const value = item[key as keyof Node]?.toString() ?? '';

            let parseDate = new Date();
            if (value && value !== '') {
              parseDate = new Date(value);
            }
            return parseDate.toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
            });
          }

          const isNumber = !isNaN(Number(item[key as keyof Node]?.toString()));
          if (isNumber) {
            return item[key as keyof Node]?.toString() ?? '0';
          }

          return item[key as keyof Node]?.toString() ?? '';
        case 'number':
          return item[key as keyof Node]?.toString() ?? '0';
        case 'boolean':
          return <boolean>item[key as keyof Node] ? 'border-b-2 border-b-primary-900 border-dashed' : '';
        case 'object':
          if (key === 'pg') {
            const pg = item[key as keyof Node] as PeopleGroup;
            return pg.name;
          }

          if (key === 'location') {
            const location = item[key as keyof Node] as DefaultMapLocation | string;

            if (typeof location === 'string') {
              return location;
            }
            if (!location) {
              return 'Select your location';
            }

            const fields = [
              location.location,
              location.level4?.name,
              location.level3?.name,
              location.level2?.name,
              location.level1?.name,
              location.country?.name,
            ];

            const selectedText = fields.find((field) => field && field !== '');
            return selectedText || '';
          }

          return '';
        default:
          return item[key as keyof Node]?.toString() ?? '';
      }
    }
    return '';
  }
}
@Pipe({
  name: 'functions',
  standalone: true,
})
export class FunctionsPipe implements PipeTransform {
  transform(key: string | undefined, item: Node, badge: 'on' | 'middle'): string {
    if (!key) return 'hidden';
    if (item && key in item) {
      const value = item[key as keyof Node]?.toString() ?? 'off';

      if (value === badge) {
        return '';
      }
      return 'hidden';
    }
    return 'hidden';
  }
}
