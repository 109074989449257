import { Pipe, PipeTransform } from '@angular/core';
import ImageCacheManager from '../helpers/cache-image.helper';

@Pipe({
  name: 'imageCache',
  standalone: true,
})
export class ImageCachePipe implements PipeTransform {
  async transform(imageUrl: string): Promise<string> {
    if (imageUrl.includes('/assets/')) {
      return imageUrl;
    }
    const imageCacheManager = new ImageCacheManager();
    const filename = imageCacheManager.extractFilenameFromUrl(imageUrl);

    // Attempt to fetch the image from the cache first
    let cachedData = await imageCacheManager.getFromCache(filename);

    // If cached data is available, return it immediately.
    if (cachedData) {
      // Check if online and update cache in the background without awaiting.
      // if (await isOnline()) {
      //   await cacheImage(imageUrl, filename).catch((error) => console.error('Error updating cache:', error, imageUrl));
      // }
      return cachedData;
    } else {
      // If not cached, check connectivity status.
      if (await imageCacheManager.isOnline()) {
        // If online, fetch the image, cache it, and return the fresh data.
        return await imageCacheManager.cacheImage(imageUrl, filename);
      } else {
        // If offline and not cached, return a default image.
        return 'assets/icons/svg/dummy.svg';
      }
    }
  }
}
