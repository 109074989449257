import { Injectable, inject } from '@angular/core';
import { HelpSupportState } from './states';
import { HelpSupport } from '../domain/models/help.model';
import { CoreStore } from './core.store';
import { HelpSupportApiService } from '../services/apis';

@Injectable({
  providedIn: 'root',
})
export class HelpSupportStore extends CoreStore<HelpSupport, HelpSupportState, HelpSupportApiService> {
  override serviceType = HelpSupportApiService;

  constructor() {
    super({ entities: [], loading: false });
  }
}
