import { Injectable } from '@angular/core';
import { CoreStore } from './core.store';
import { MapPermission } from '../domain';
import { MapPermissionState } from './states';
import { catchError, finalize, forkJoin, from, Observable, of, switchMap, tap } from 'rxjs';
import { MapPermissionAPIService } from '../services/apis';
import { generateUniqueRedeemCode, MapRoleType } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class MapPermissionStore extends CoreStore<MapPermission, MapPermissionState, MapPermissionAPIService> {
  override serviceType = MapPermissionAPIService;
  constructor() {
    super({ entities: [], loading: false, error: '', selectedEntity: undefined });
  }

  readonly transferPermissions = this.effect<{
    oldMapId: string;
    newMapId: string;
    newAdminUserId: string;
    newStartNodeId: string;
  }>((params$) =>
    params$.pipe(
      switchMap((params) => this.transferPermissionsLogic(params)),
      finalize(() => this.loadEntities({})),
    ),
  );

  readonly inviteNewUser = this.effect<{
    mapId: string;
    role: MapRoleType;
    startNodeId?: string;
    callback?: (entity: MapPermission) => void;
  }>((params$) =>
    params$.pipe(
      switchMap((params) => {
        const newCode = generateUniqueRedeemCode('INVITE');
        const entity: MapPermission = {
          mapId: params.mapId,
          role: params.role,
          startNodeId: params.startNodeId,
          redeemCode: newCode,
        };
        this.setLoading(true);
        return this.service.invite(entity).pipe(
          tap({
            next: (response) => {
              this.updater((state) => ({
                ...state,
                entities: [...state.entities, response],
              }))();
              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: any) => {
              console.error('Error creating entity:', error);
              this.setError(error.message);
            },
          }),
          finalize(() => {
            this.setLoading(false);
          }),
          catchError((error) => {
            console.error('Caught error:', error);
            this.setError(error.message);
            return of([]);
          }),
        );
      }),
    ),
  );

  readonly acceptInvite = this.effect<{
    redeemCode: string;
    callback?: (entity: MapPermission) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) =>
        from(this.loadEntityWithInviteCode(params.redeemCode)).pipe(
          switchMap((entity) => {
            if (entity) {
              const mapId = typeof entity.mapId === 'string' ? entity.mapId : entity.mapId.id;
              return of(
                this.updateEntity({
                  id: entity.id!,
                  entity: { ...entity, userId: this.service.getUserId()!, mapId },
                  callback: params.callback,
                }),
              );
            } else {
              this.setError('Invalid redeem code');
              return of(null);
            }
          }),
        ),
      ),
      catchError((error) => {
        console.error('Caught error:', error);
        this.setError(error.message);
        return of(null);
      }),
      finalize(() => this.setLoading(false)),
    ),
  );

  private async loadEntityWithInviteCode(redeemCode: string): Promise<MapPermission | null> {
    return new Promise((resolve) => {
      this.loadEntities({
        options: { where: [{ field: 'redeemCode', operator: '==', value: redeemCode }] },
        callback: (entities) => {
          if (entities.length > 0) {
            resolve(entities[0]);
          } else {
            resolve(null);
          }
        },
      });
    });
  }

  private transferPermissionsLogic(params: {
    oldMapId: string;
    newMapId: string;
    newAdminUserId: string;
    newStartNodeId: string;
  }): Observable<MapPermission[]> {
    return this.service.getAll({ where: [{ field: 'mapId', operator: '==', value: params.oldMapId }] }).pipe(
      switchMap((existingPermissions) => {
        // Transfer existing permissions
        const permissionTransfers = existingPermissions.map((permission) =>
          this.service.create({
            ...permission,
            id: undefined, // Remove id to create a new entity
            mapId: params.newMapId,
            startNodeId: params.newStartNodeId,
          }),
        );

        // Create new admin permission
        const newAdminPermission: MapPermission = {
          mapId: params.newMapId,
          userId: params.newAdminUserId,
          role: 'admin',
          startNodeId: params.newStartNodeId,
        };
        permissionTransfers.push(this.service.create(newAdminPermission));

        return forkJoin(permissionTransfers);
      }),
    );
  }
}
