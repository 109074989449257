import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { MapPermission } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';
import { catchError, EMPTY, map, Observable, switchMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class MapPermissionAPIService extends CoreApiService<MapPermission> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<MapPermission>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'map_permissions';
  protected getServiceIdentifier(): string {
    return 'MapPermissionApiService';
  }

  invite(data: MapPermission & { [x: string]: any }): Observable<MapPermission> {
    const id = uuidv4();
    (data as any)['createdAt'] = new Date().toISOString();
    (data as any)['id'] = id;
    (data as any)['userId'] = '';
    const endpoint = `${this.url}/${this.dbPath}`;

    if (navigator.onLine) {
      return this.http.post<MapPermission>(endpoint, data).pipe(
        switchMap((response) =>
          this.dataService.postData<MapPermission>(this.dbPath, endpoint, response).pipe(map(() => response)),
        ),
        catchError((error) =>
          this.dataService.saveTransaction(this.dbPath, id, data, 'POST', endpoint).pipe(switchMap(() => EMPTY)),
        ),
      );
    } else {
      return this.dataService.saveTransaction(this.dbPath, id, data, 'POST', endpoint).pipe(
        switchMap((data: MapPermission | null) => {
          if (data) {
            return new Observable<MapPermission>((subscriber) => {
              subscriber.next(data);
            });
          } else {
            return new Observable<MapPermission>((subscriber) => {
              subscriber.error('Error');
            });
          }
        }),
      );
    }
  }
}
