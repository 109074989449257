import { MapConnection, TreeNodeMapCollection } from 'src/app/domain';
import { Map, Node } from 'src/app/domain/models/map.model';

export function buildTree(items: Node[], mapConnections: MapConnection[] = []): TreeNodeMapCollection[] {
  if (!items) return [];

  const rootItems: TreeNodeMapCollection[] = [];
  const lookup: Record<string, TreeNodeMapCollection> = {};

  // Initialize lookup map and assign an empty array for potential children
  items.forEach((item) => {
    lookup[item.id!] = { ...item, children: [], isShared: false };
  });

  // Populate children arrays and identify root items
  items.forEach((item) => {
    if (item.parentId && lookup[item.parentId]) {
      const children = lookup[item.parentId].children as TreeNodeMapCollection[];
      children.push(lookup[item.id!]);
    } else {
      rootItems.push(lookup[item.id!]);
    }
  });

  // Process map connections
  mapConnections.forEach((connection) => {
    const upstreamNode = lookup[connection.upstreamNodeId];
    if (upstreamNode) {
      const nodes = (connection.downstreamMapId as Map).nodes;
      const map = connection.downstreamMapId as Map;
      const connectedNodes = buildTreeWithSharedFlag(nodes, connection.id!, map.id!);
      upstreamNode.children = upstreamNode.children || [];
      upstreamNode.children.push(...connectedNodes);
    } else {
      const nodes = (connection.downstreamMapId as Map)?.nodes;
      if (!nodes) return;
      const map = connection.downstreamMapId as Map;
      const connectedNodes = buildTreeWithSharedFlag(nodes, connection.id!, map.id!);
      rootItems.push(...connectedNodes);
    }
  });

  // Recursive function to set level
  function assignLevels(items: TreeNodeMapCollection[], level: number): void {
    items.forEach((item) => {
      item.level = level;
      if (item.children && item.children.length > 0) {
        assignLevels(item.children, level + 1);
      }
    });
  }

  // Assign levels starting with root items at level 1
  assignLevels(rootItems, 1);

  return rootItems;
}

function buildTreeWithSharedFlag(items: Node[], mapId: string, connectionId: string): TreeNodeMapCollection[] {
  if (!items) return [];

  const rootItems: TreeNodeMapCollection[] = [];
  const lookup: Record<string, TreeNodeMapCollection> = {};

  // Initialize lookup map and assign an empty array for potential children
  items.forEach((item) => {
    lookup[item.id!] = { ...item, children: [], isShared: true, mapId, connectionId };
  });

  // Populate children arrays and identify root items
  items.forEach((item) => {
    if (item.parentId && lookup[item.parentId]) {
      const children = lookup[item.parentId].children as TreeNodeMapCollection[];
      children.push(lookup[item.id!]);
    } else {
      rootItems.push(lookup[item.id!]);
    }
  });

  return rootItems;
}

export function buildMapTree(maps: Map[], connections: MapConnection[]): Map[] {
  if (!maps) return [];

  const rootItems: Map[] = [];
  const lookup: Record<string, Map> = {};

  // Initialize lookup map and assign an empty array for potential children
  maps.forEach((item) => {
    lookup[item.id!] = { ...item, children: [] };
  });

  // Recursive function to set level
  function assignLevels(items: Map[], level: number): void {
    items.forEach((item) => {
      item['level'] = level;
      if ((item['children'] as Map[]).length > 0) {
        assignLevels(item['children'] as Map[], level + 1);
      }
    });
  }

  // Assign levels starting with root items at level 1
  assignLevels(rootItems, 1);
  return maps;
}

export function buildFlatTree(map: Map, connections: MapConnection[]): Map {
  const newMap = Object.assign({} as Map, map);
  const nodes = Object.assign([] as Node[], newMap.nodes);
  const newConnections = Object.assign([] as MapConnection[], connections);

  for (const connection of newConnections) {
    let connectionNodes = (connection.downstreamMapId as Map).nodes;
    if (connection.upstreamMapId !== '') {
      for (const node of connectionNodes) {
        if (!node.parentId || node.parentId === '') node.parentId = connection.upstreamNodeId;
      }
    }

    connectionNodes = connectionNodes.map((node) => {
      return {
        ...node,
        isShared: true,
      };
    });
    nodes.push(...connectionNodes);
  }

  newMap.nodes = nodes;

  return newMap;
}
