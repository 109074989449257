import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Auth } from '@angular/fire/auth';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  tap,
} from 'rxjs';
import {
  Map,
  Organization,
  Template,
  Node,
  MonthlyAuditLog,
  MapPermission,
  UnifiedUserInfo,
  MapConnection,
} from 'src/app/domain';
import { ApplicationInitializerService } from 'src/app/services';
import {
  generateUniqueId,
  getNodeFamilyTreeFromMap,
  getNodeWithDescendantsFromMap,
  MapRoleType,
  parseLocation,
  parseMapId,
  parseUserId,
} from 'src/app/shared';
import {
  MapConnectionStore,
  MapPermissionStore,
  MapStore,
  OrganizationStore,
  TemplateStore,
  UserStore,
} from 'src/app/store';
import { AuditStore } from 'src/app/store/audit.store';
import { ToastController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  userStore = inject(UserStore);
  mapStore = inject(MapStore);
  templateStore = inject(TemplateStore);
  organizationStore = inject(OrganizationStore);
  auditStore = inject(AuditStore);
  mapPermissionStore = inject(MapPermissionStore);
  mapConnectionStore = inject(MapConnectionStore);
  destroyRef = inject(DestroyRef);
  auth = inject(Auth);
  appInitializer = inject(ApplicationInitializerService);
  toastCtrl = inject(ToastController);

  private user = this.userStore.user$;
  private currentMapSubject = new BehaviorSubject<Map | null>(null);
  private defaultMapSubject = new BehaviorSubject<Map | null>(null);
  private currentTemplateSubject = new BehaviorSubject<Template | null>(null);
  private currentOrganizationSubject = new BehaviorSubject<Organization | null>(null);
  private connectionMapsSubject = new BehaviorSubject<MapConnection[]>([]);
  private auditSubject = new BehaviorSubject<MonthlyAuditLog[]>([]);
  private isPrivateSubject = new BehaviorSubject<boolean>(false);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private isStreamFiltered = new BehaviorSubject<boolean>(false);

  private loadingMap = this.mapStore.loading$;
  private loadingTemplate = this.templateStore.loading$;
  private loadingOrganization = this.organizationStore.loading$;
  private loadingAudit = this.auditStore.loading$;
  private errorSubject = new Subject<string>();
  private mapError$ = this.mapStore.error$;
  private templateError$ = this.templateStore.error$;
  private organizationError$ = this.organizationStore.error$;
  private mapId: string = '';

  constructor() {
    this.begin();
  }

  begin() {
    combineLatest([
      this.mapError$.pipe(startWith(null)),
      this.templateError$.pipe(startWith(null)),
      this.organizationError$.pipe(startWith(null)),
    ])
      .pipe(
        map(([mapError, templateError, organizationError]) => mapError || templateError || organizationError),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        if (error) this.errorSubject.next(error);
      });

    // Loading state
    combineLatest([
      this.loadingMap.pipe(startWith(false)),
      this.loadingTemplate.pipe(startWith(false)),
      this.loadingOrganization.pipe(startWith(false)),
    ])
      .pipe(
        tap(([loadingMap, loadingTemplate, loadingOrganization]) => {}),
        map(
          ([loadingMap, loadingTemplate, loadingOrganization]) => loadingMap || loadingTemplate || loadingOrganization,
        ),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((isLoading) => {
        this.isLoadingSubject.next(isLoading);
      });

    combineLatest([
      this.appInitializer.mapUpdateNotifier.pipe(startWith(null)),
      this.appInitializer.mapDeleteNotifier.pipe(startWith(null)),
    ])
      .pipe(
        map(([updateId, deleteId]) => updateId || deleteId),
        filter((id) => !!id),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((id) => {
        this.mapStore.loadEntities({ useCache: false, callback: () => this.init(this.mapId) });
      });

    this.error$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (error) => {
      if (error) {
        const toast = await this.toastCtrl.create({
          message: 'Something went wrong! \n' + error,
          duration: 2000,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
            },
          ],
        });

        await toast.present();
        this.clearError();
        this.isLoadingSubject.next(false);
      }
    });

    this.initializeUser();
  }

  init(mapId: string) {
    if (!mapId || mapId === '') return;
    this.mapId = mapId;
    this.mapStore.setClearSelectedEntity();

    // Convert mapStore.loadEntity to a Promise
    const loadMapEntity = new Promise<Map | null>((resolve, reject) => {
      this.mapStore.loadEntity({
        id: mapId,
        callback: (entity?: Map | null) => {
          if (entity) {
            this.currentMapSubject.next(entity);
            this.defaultMapSubject.next(entity);
            // Convert templateStore.loadEntity to a Promise
            this.templateStore.loadEntity({
              id: entity.templateId,
              callback: (templateEntity: Template | null) => {
                this.currentTemplateSubject.next(templateEntity);
                resolve(entity);
              },
            });
          } else {
            this.currentMapSubject.next(null);
            this.defaultMapSubject.next(null);
            resolve(null);
          }
        },
      });
    });

    // Convert mapConnectionStore.loadEntities to a Promise
    const loadMapConnections = new Promise((resolve, reject) => {
      this.mapConnectionStore.loadEntities({
        useCache: true,
        callback: (entities) => {
          const connections = entities.filter((connection) => parseMapId(connection.upstreamMapId) === mapId);
          this.connectionMapsSubject.next(connections);
          resolve(entities);
        },
      });
    });

    // Convert mapPermissionStore.loadEntities to a Promise
    const loadMapPermissions = new Promise((resolve, reject) => {
      this.mapPermissionStore.loadEntities({
        options: {
          where: [
            {
              field: 'mapId',
              operator: '==',
              value: mapId,
            },
          ],
        },
        useCache: true,
        callback: (permissions) => {
          const permission = permissions
            .filter((permission) => parseUserId(permission.userId!) === this.userId)
            .find((permission) => parseMapId(permission.mapId) === mapId);
          if (permission && permission.startNodeId && permission.startNodeId !== '') {
            const filterNodes = getNodeWithDescendantsFromMap(this.currentMapSubject.value!, permission.startNodeId);
            this.currentMapSubject.next({ ...this.currentMapSubject.value!, nodes: filterNodes });
          }
          resolve(permission);
        },
      });
    });

    // Convert loadAuditLogs to a Promise
    const loadAuditLogsPromise = this.loadAuditLogs(mapId);

    // Run all promises in parallel
    Promise.all([loadMapEntity, loadMapConnections, loadMapPermissions, loadAuditLogsPromise])
      .then(([mapEntity, connections, permissions, auditLogs]) => {})
      .catch((error) => {
        // Handle any error that occurred during the execution
        console.error('Error loading data', error);
      });
  }

  initializeUser() {
    this.userStore.loadEntity({
      id: this.userId,
      callback: (user) => {
        if (user) {
          this.organizationStore.loadEntity({
            id: user.organizationId!,
            callback: (entity) => {
              if (entity) {
                this.onSetCurrentOrganization(entity);
                this.onSetIsPrivate(entity.is_private);
                this.templateStore.setEntities(entity.templateIds as Template[]);
              }
            },
          });
        }
      },
    });
  }

  initUpstreamMap(mapId: string) {
    if (!mapId || mapId === '') return;

    this.mapStore.loadEntity({
      id: mapId,
      callback: (entity?: Map | null) => {
        if (entity) {
          this.currentMapSubject.next(entity);
          this.defaultMapSubject.next(entity);
          this.templateStore.loadEntity({
            id: entity.templateId,
            callback: (entity: Template | null) => {
              this.currentTemplateSubject.next(entity);
            },
          });
        } else {
          this.currentMapSubject.next(null);
          this.defaultMapSubject.next(null);
        }
      },
    });

    this.organizationStore.loadEntities({
      useCache: true,
      callback: (entities) => {
        if (entities.length > 0) {
          this.organizationStore.setSelectedEntity(entities[0]);
          this.currentOrganizationSubject.next(entities[0]);
        }
      },
    });

    this.mapConnectionStore.loadEntities({
      useCache: false,
      options: {
        where: [
          {
            field: 'downstreamMapId',
            operator: '==',
            value: mapId,
          },
        ],
      },
      callback: (entities) => {
        this.connectionMapsSubject.next(entities);
      },
    });

    this.mapPermissionStore.loadEntities({
      options: {
        where: [
          {
            field: 'mapId',
            operator: '==',
            value: mapId,
          },
        ],
      },
      useCache: true,
      callback: (permissions) => {
        const permission = permissions
          .filter((permission) => parseUserId(permission.userId!) === this.userId)
          .find((permission) => parseMapId(permission.mapId) === mapId);
        if (permission && permission.startNodeId && permission.startNodeId !== '') {
          const filterNodes = getNodeWithDescendantsFromMap(this.currentMapSubject.value!, permission.startNodeId);

          this.currentMapSubject.next({ ...this.currentMapSubject.value!, nodes: filterNodes });
        }
      },
    });
  }

  initNewGathering() {
    const currentMap = this.currentMapSubject.value;
    const currentTemplate = this.currentTemplateSubject.value;

    const numericFields = currentTemplate?.fields.filter((field) => field.type === 'numeric');

    const createNewNode = (map: Map) => {
      const newGathering: Node = {
        id: generateUniqueId(),
        founder: '',
        leader: map?.leader ?? '',
        location: map?.location ?? '',
        start_date: new Date().toISOString(),
        group_or_church: 'group',
        is_active: true,
        ...numericFields
          ?.map((field) => ({ [field.name ?? '']: 0 }))
          .reduce((acc, field) => ({ ...acc, ...field }), {}),
      };

      return newGathering;
    };

    return createNewNode(currentMap!);
  }

  initNode(nodeId: string) {
    const currentMap = this.findMapWithNodeId(nodeId).map;
    const node = currentMap?.nodes?.find((node) => node.id === nodeId) ?? this.initNewGathering();

    return node;
  }

  async checkPermission(entity: Map, currentMap: Map, isCurrentMap: boolean) {
    if (!isCurrentMap) {
      const connection = this.findConnection(entity.id!, currentMap.id!);
      if (connection) {
        const hasPermission = connection.role !== 'view';
        return hasPermission;
      } else return false;
    }

    const checkPermission = new Promise((resolve) => {
      this.mapPermissionStore.loadEntities({
        options: {
          where: [
            {
              field: 'mapId',
              operator: '==',
              value: isCurrentMap ? entity.id! : currentMap.id!,
            },
          ],
        },
        useCache: true,
        callback: (permissions) => {
          if (permissions.length > 0) {
            const permission = permissions.find((permission) => parseUserId(permission.userId!) === this.userId);
            if (permission && permission.role !== 'view') {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        },
      });
    });

    return await checkPermission;
  }

  async updateEntity(entity: Map, callback?: (data: Map | null) => void) {
    const currentMap = this.currentMapSubject.value;
    if (!currentMap) return;
    const isCurrentMap = currentMap.id === entity.id;
    const hasPermission = await this.checkPermission(entity, currentMap, isCurrentMap);
    if (!hasPermission) {
      this.errorSubject.next('You do not have permission to edit this map');
      return;
    }
    this.mapStore.updateEntity({
      id: entity.id!,
      entity,
      callback,
    });
  }

  reloadEntities(callback?: () => void, useCache = true) {
    const loadMapEntities = new Promise<void>((resolve) => {
      this.mapStore.loadEntities({
        useCache: useCache,
        callback: () => resolve(),
      });
    });

    const loadMapConnections = new Promise<void>((resolve) => {
      this.mapConnectionStore.loadEntities({
        useCache: useCache,
        callback: () => resolve(),
      });
    });

    const loadMapPermissions = new Promise<void>((resolve) => {
      this.mapPermissionStore.loadEntities({
        useCache: useCache,
        callback: () => resolve(),
      });
    });

    Promise.all([loadMapEntities, loadMapConnections, loadMapPermissions])
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error('Error loading entities:', error);
        if (callback) {
          callback();
        }
      });
  }

  moveEntity(mapId: string, sourceId: string, destinationId: string, callback?: () => void) {
    this.mapStore.moveEntity({
      mapId,
      sourceNodeId: sourceId,
      destinationNodeId: destinationId,
      callback,
    });
  }

  async deleteNode(mapId: string, nodeId: string, callback?: () => void) {
    const { map, currentMap, isCurrentMap } = this.findMapWithNodeId(nodeId);

    if (!map) return;

    const hasPermission = await this.checkPermission(map, currentMap!, isCurrentMap);
    if (!hasPermission) {
      this.errorSubject.next('You do not have permission to edit this map');
      return;
    }

    const deletingNode = map.nodes.find((node) => node.id === nodeId);
    map.nodes.forEach((node) => {
      if (node.parentId === nodeId) node.parentId = deletingNode?.parentId ?? '';
    });

    this.mapStore.updateEntity({
      id: map.id!,
      entity: map,
      callback: (entity) => {
        if (entity) {
          this.mapStore.deleteNode({
            mapId,
            nodeId,
            callback,
          });
        }
      },
    });
  }

  async deleteMap(mapId: string, callback?: () => void) {
    const getMap = new Promise<Map | null>((resolve) => {
      this.mapStore.loadEntity({
        id: mapId,
        callback: (entity) => {
          resolve(entity);
        },
      });
    });

    const entity = await getMap;
    if (!entity) {
      this.errorSubject.next('Map not found');
      return;
    }
    const currentMap = this.currentMapSubject.value;
    const isCurrentMap = currentMap?.id === mapId;
    const hasPermission = await this.checkPermission(entity, currentMap!, isCurrentMap);
    if (!hasPermission) {
      this.errorSubject.next('You do not have permission to edit this map');
      return;
    }

    this.mapStore.deleteEntity({
      id: mapId,
      callback,
    });
  }

  lockMap(mapId: string, callback?: () => void) {
    const map = this.currentMapSubject.value;
    if (!map || mapId !== map.id) return;

    this.mapStore.updateEntity({
      id: map?.id!,
      entity: { ...map!, lock: !map.lock },
      callback: (entity) => {
        if (entity) {
          this.currentMapSubject.next(entity);
          callback?.();
        }
      },
    });
  }

  deleteUser(permissionId: string, callback?: () => void) {
    this.mapPermissionStore.deleteEntity({
      id: permissionId,
      callback,
    });
  }

  updateUserRole(permissionId: string, role: MapRoleType, callback?: () => void) {
    this.mapPermissionStore.updateEntity({
      id: permissionId,
      entity: { role },
      callback,
    });
  }

  deleteMember(permissionId: string, callback?: () => void) {
    this.mapPermissionStore.deleteEntity({
      id: permissionId,
      callback,
    });
  }

  clearError() {
    this.errorSubject.next('');
    this.mapStore.setError('');
    this.templateStore.setError('');
    this.organizationStore.setError('');
  }

  filterStream(nodeId: string, childOnly: string) {
    const currentMap = this.currentMapSubject.value;
    if (currentMap) {
      const nodes = getNodeFamilyTreeFromMap(currentMap, nodeId, childOnly);
      currentMap!.nodes = nodes;

      this.currentMapSubject.next(currentMap);
      this.isStreamFiltered.next(true);
    }
  }

  unFilterStream() {
    if (this.defaultMapSubject.value === null) return;
    this.reloadEntities(() => {
      this.init(this.mapId);
      this.isStreamFiltered.next(false);
    });
  }

  loadAuditLogs(mapId: string) {
    this.auditStore.loadMonthlyAuditLogs({
      mapId,
      callback: (data) => {
        this.auditSubject.next(data);
      },
    });
  }

  updateCurrentMapManually(map: Map) {
    this.currentMapSubject.next(map);
  }

  generatePDF(mapId: string, nodeId: string, callback?: () => void) {
    this.mapStore.generatePDF({
      mapId,
      nodeId,
      callback,
    });
  }

  copyMap(mapId: string, callback?: () => void) {
    this.mapStore.copyMap({
      mapId,
      callback,
    });
  }

  generateSplitStreamCode(mapId: string, nodeId: string, callback?: (code: string) => void) {
    this.mapConnectionStore.splitOffStream({
      upstreamMapId: mapId,
      upstreamNodeId: nodeId,
      callback: (connection) => {
        callback?.(connection.redeemCode!);
      },
    });
  }

  checkInviteRedeemCode(redeemCode: string, callback?: (isCodeValid: boolean) => void) {
    this.mapPermissionStore.loadEntities({
      options: {
        where: [
          {
            field: 'redeemCode',
            operator: '==',
            value: redeemCode,
          },
          {
            field: 'userId',
            operator: '==',
            value: '',
          },
        ],
      },
      useCache: false,
      callback: (permissions) => {
        if (permissions.length > 0) {
          const permission = permissions[0];
          if (permission.userId && permission.userId !== '') {
            callback?.(false);
            return;
          }

          callback?.(true);
          return;
        }
      },
    });
  }

  checkSplitStreamCode(redeemCode: string, callback?: (isCodeValid: boolean) => void) {
    this.mapConnectionStore.getByRedeemCode(redeemCode).subscribe((connection) => {
      if (connection) {
        callback?.(true);
        return;
      }

      callback?.(false);
    });
  }

  generateJoinMapCode(mapId: string, nodeId: string, callback?: (code: string) => void) {
    this.mapConnectionStore.joinChildMap({
      upstreamMapId: mapId,
      upstreamNodeId: nodeId,
      callback: (connection) => {
        callback?.(connection.redeemCode!);
      },
    });
  }

  checkJoinMapCode(redeemCode: string, callback?: (isCodeValid: boolean) => void) {
    this.mapConnectionStore.getByRedeemCode(redeemCode).subscribe((connection) => {
      if (connection) {
        callback?.(true);
        return;
      }

      callback?.(false);
    });
  }

  redeemSplitUpStream(redeemCode: string, callback?: (entity: MapConnection) => void) {
    this.mapStore.redeemSplitOffStream({
      redeemCode,
      callback,
    });
  }

  redeemJoinUpStream(redeemCode: string, downstreamMapId: string, callback?: (entity: MapConnection) => void) {
    this.mapStore.loadMapWithJoinCode({
      redeemCode,
      downstreamMapId,
      callback,
    });
  }

  loadMapWithInviteCode(redeemCode: string, callback?: (map: Map | null, role: MapRoleType) => void) {
    this.mapStore.loadMapWithInviteCode({
      redeemCode,
      callback,
    });
  }

  acceptInvite(redeemCode: string, callback?: (entity: MapPermission) => void) {
    this.mapPermissionStore.acceptInvite({
      redeemCode,
      callback,
    });
  }

  findMapWithNodeId(nodeId: string): { map: Map | null; currentMap: Map | null; isCurrentMap: boolean } {
    const currentMap = this.currentMapSubject.value;
    const connectionMaps = this.connectionMapsSubject.value.map((connection) => connection.downstreamMapId as Map);
    let isCurrentMap = true;
    if (!currentMap) return { map: null, currentMap: null, isCurrentMap };
    const node = currentMap.nodes.find((node) => node.id === nodeId);
    if (node) return { map: currentMap, currentMap, isCurrentMap };
    const map = connectionMaps.find((map) => map.nodes.find((node) => node.id === nodeId));
    return { map: map ?? null, currentMap, isCurrentMap: false };
  }

  findNodeTemplateWithNodeId(nodeId: string): Template {
    if (!nodeId || nodeId.length === 0) return this.currentTemplateSubject.value!;
    const map = this.findMapWithNodeId(nodeId).map;
    const templateId = map?.templateId;
    const template = this.templateStore.entities().find((template) => template.id === templateId);

    if (template) return template;
    return this.currentTemplateSubject.value!;
  }

  findConnection(downstreamMapId: string, upstreamMapId: string) {
    const connection = this.connectionMapsSubject.value.find(
      (connection) =>
        (connection.downstreamMapId as Map).id === downstreamMapId &&
        (connection.upstreamMapId as Map).id === upstreamMapId,
    );

    return connection;
  }

  unlinkMaps(id: string, callback?: () => void) {
    this.mapConnectionStore.deleteEntity({
      id,
      callback,
    });
  }

  updateConnectionRole(id: string, role: MapRoleType, callback?: () => void) {
    this.mapConnectionStore.updateEntity({
      id,
      entity: { role },
      callback,
    });
  }

  generateUpstreamSharingCode(mapId: string, callback?: (code: string) => void) {
    this.mapConnectionStore.upstream({
      downstreamMapId: mapId,
      callback: (connection) => {
        callback?.(connection.redeemCode!);
      },
    });
  }

  checkUpstreamCode(redeemCode: string, callback?: (isCodeValid: boolean) => void) {
    this.mapConnectionStore.getByRedeemCode(redeemCode).subscribe((connection) => {
      if (connection) {
        callback?.(true);
        return;
      }

      callback?.(false);
    });
  }

  onRedeemJoinUpStream(redeemCode: string, upstreamMapId: string, upstreamNodeId: string, callback?: () => void) {
    this.mapConnectionStore.redeemJoinUpStream({
      redeemCode,
      upstreamMapId,
      upstreamNodeId,
      callback,
    });
  }

  onSetCurrentOrganization(organization: Organization) {
    this.currentOrganizationSubject.next(organization);
  }

  onSetIsPrivate(isPrivate: boolean) {
    this.isPrivateSubject.next(isPrivate);
  }

  async generateShareDialogExtra(mapId: string, type: string, nodeId?: string): Promise<string> {
    const getMap = new Promise<Map | null>((resolve) => {
      this.mapStore.loadEntity({
        id: mapId,
        callback: (entity) => {
          resolve(entity);
        },
      });
    });

    const map = await getMap;
    if (!map) return 'Map Not Found!';

    let extra = '';
    extra = `${type} ${map.name} ${map.location && map.location !== '' ? ' - ' + parseLocation(map.location) : ''}`;

    if (nodeId && nodeId !== '') {
      const node = map.nodes.find((node) => node.id === nodeId);
      if (node) extra += `. \n From ${node.leader} ${node.location ? ' - ' + parseLocation(node.location) : ''}`;
    }

    return extra;
  }

  public get currentMap$(): Observable<Map | null> {
    return this.currentMapSubject.asObservable();
  }

  public get connectionMaps$(): Observable<MapConnection[]> {
    return this.connectionMapsSubject.asObservable();
  }

  public get currentTemplate$(): Observable<Template | null> {
    return this.currentTemplateSubject.asObservable();
  }

  public get currentOrganization$(): Observable<Organization | null> {
    return this.currentOrganizationSubject.asObservable();
  }

  public get isPrivate$(): Observable<boolean> {
    return this.isPrivateSubject.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  public get loadingAudit$(): Observable<boolean> {
    return this.loadingAudit;
  }

  public get error$(): Observable<string> {
    return this.errorSubject.asObservable();
  }

  public get isStreamFiltered$(): Observable<boolean> {
    return this.isStreamFiltered.asObservable();
  }

  public get audits$(): Observable<MonthlyAuditLog[]> {
    return this.auditSubject.asObservable();
  }

  public get userId(): string {
    return this.auth.currentUser?.uid ?? '';
  }
}
