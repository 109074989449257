import { Injectable, inject } from '@angular/core';
import { PeopleGroupState } from './states';
import { PeopleGroup } from '../domain';
import { CoreStore } from './core.store';
import { PeopleGroupApiService } from '../services/apis';
import { of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PeopleGroupStore extends CoreStore<PeopleGroup, PeopleGroupState, PeopleGroupApiService> {
  protected override serviceType = PeopleGroupApiService;

  constructor() {
    super({ entities: [], loading: false, syncStatus: false });
  }

  readonly syncStatus = this.selectSignal((state) => state.syncStatus);

  readonly loadPeopleGroupSyncStatus = this.effect((params$) =>
    params$.pipe(
      switchMap(() =>
        this.service.getSyncStatus().pipe(
          switchMap((status) => {
            this.patchState({ syncStatus: status });
            return of(status);
          }),
        ),
      ),
    ),
  );
}
