import { Injectable } from '@angular/core';
import { NotificationState } from './states';
import { Notification } from '../domain/models/notification.model';
import { NotificationApiService } from '../services/apis';
import { CoreStore } from './core.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationStore extends CoreStore<Notification, NotificationState, NotificationApiService> {
  override serviceType = NotificationApiService;

  constructor() {
    super({ entities: [], loading: false });
  }
}
