import { Injectable, inject } from '@angular/core';
import { catchError, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { UserState } from './states';
import { UnifiedUserInfo } from '../domain/models/user.model';
import { MapStore } from './map.store';
import { NotificationStore } from './notification.store';
import { MenuItem } from '../domain';
import { UserApiService } from '../services/apis';
import { CoreStore } from './core.store';
import { TemplateStore } from './template.store';
import { MapPermissionStore } from './map-permission.store';
import { MapConnectionStore } from './map-connection.store';
import { OrganizationStore } from './organization.store';

@Injectable({
  providedIn: 'root',
})
export class UserStore extends CoreStore<UnifiedUserInfo, UserState, UserApiService> {
  protected serviceType = UserApiService;

  mapStore = inject(MapStore);
  notificationStore = inject(NotificationStore);
  templateStore = inject(TemplateStore);
  mapPermissionStore = inject(MapPermissionStore);
  mapConnectionStore = inject(MapConnectionStore);
  organizationStore = inject(OrganizationStore);

  constructor() {
    super({ entities: [], loading: false });
  }

  readonly user$ = this.select((state) => state.selectedEntity);
  readonly menuItems$ = this.select((state) => state.menuItems ?? []);

  // State updaters

  readonly setMenuItems = this.updater((state, menuItems: MenuItem[]) => ({
    ...state,
    menuItems,
  }));

  // Effects

  readonly getUsers = this.effect<string[]>((ids$) =>
    ids$.pipe(
      switchMap((ids) => {
        this.setLoading(true);
        return this.service.getUsers(ids).pipe(
          tap({
            next: (entities) => {
              this.setEntities(entities);
              this.setLoading(false);
            },
            error: (error) => {
              this.setError(error.message);
              this.setLoading(false);
            },
          }),
          catchError(() => of([])), // Handle error internally
        );
      }),
    ),
  );

  readonly findUsers = this.effect<string>((queryString$) =>
    queryString$.pipe(
      switchMap((queryString) => {
        this.setLoading(true);
        return this.service.findUsers(queryString).pipe(
          tap({
            next: (entities) => {
              this.setEntities(entities);
              this.setLoading(false);
            },
            error: (error) => {
              this.setError(error.message);
              this.setLoading(false);
            },
          }),
          catchError(() => of([])), // Handle error internally
        );
      }),
    ),
  );

  readonly getAllUsers = this.effect<{ search?: string }>((trigger$) =>
    trigger$.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev === curr;
      }),
      switchMap((trigger) => {
        this.setLoading(true);
        return this.service.getAllUsers(trigger.search).pipe(
          tap({
            next: (entities) => {
              this.setEntities(entities);
              this.setLoading(false);
            },
            error: (error) => {
              this.setError(error.message);
              this.setLoading(false);
            },
          }),
          catchError(() => of([])),
        );
      }),
    ),
  );

  readonly getProfile = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.setClearStore();
        this.mapStore.setClearStore();
        this.mapPermissionStore.setClearStore();
        this.mapConnectionStore.setClearStore();
        this.templateStore.setClearStore();
        this.notificationStore.setClearStore();
        this.organizationStore.setClearStore();

        this.setLoading(true);
        this.mapStore.setLoading(true);
        this.mapPermissionStore.setLoading(true);
        this.mapConnectionStore.setLoading(true);
        this.templateStore.setLoading(true);
        this.notificationStore.setLoading(true);
        this.organizationStore.setLoading(true);
      }),
      switchMap(() => {
        this.setLoading(true);
        return this.service.getProfile().pipe(
          tap({
            next: (entity) => {
              const user = entity.user;
              this.setSelectedEntity(entity.user);
              this.mapStore.setEntities(entity.maps);
              this.mapPermissionStore.setEntities(entity.map_permissions);
              this.mapConnectionStore.setEntities(entity.map_connections);
              this.templateStore.setEntities(entity.templates);
              this.notificationStore.setEntities(entity.notifications);
              this.organizationStore.setEntities(entity.organizations);
              this.setMenuItems(entity.menu_items);
              this.setLoading(false);
              this.mapStore.setLoading(false);
              this.mapPermissionStore.setLoading(false);
              this.mapConnectionStore.setLoading(false);
              this.templateStore.setLoading(false);
              this.notificationStore.setLoading(false);
              this.organizationStore.setLoading(false);
            },
            error: (error) => {
              this.setError(error.message);
              this.setLoading(false);
            },
          }),
          catchError(() => of([])),
        );
      }),
    ),
  );

  readonly signOut = this.effect<{ callback?: () => void }>((trigger$) =>
    trigger$.pipe(
      switchMap((trigger) => {
        this.setLoading(true);
        this.setClearStore();
        this.mapStore.setClearStore();
        this.mapPermissionStore.setClearStore();
        this.mapConnectionStore.setClearStore();
        this.templateStore.setClearStore();
        this.notificationStore.setClearStore();
        this.organizationStore.setClearStore();
        this.setLoading(false);
        if (trigger.callback) {
          trigger.callback();
        }
        return of(null);
      }),
    ),
  );
}
