import { Injectable } from '@angular/core';
import { CountryState } from './states';
import { Country } from '../domain';
import { CoreStore } from './core.store';
import { CountryApiService } from '../services/apis';

@Injectable({
  providedIn: 'root',
})
export class CountryStore extends CoreStore<Country, CountryState, CountryApiService> {
  override serviceType = CountryApiService;

  constructor() {
    super({ entities: [], loading: false });
  }
}
