import { DestroyRef, inject, Injectable } from '@angular/core';
import { Country } from 'src/app/domain';
import { CoreApiService } from './core.service';
import { QueryOptions } from '../types';
import { from, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';
import { Network } from '@capacitor/network';
import { IndexableType } from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class CountryApiService extends CoreApiService<Country> {
  destroyRef = inject(DestroyRef);
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<Country>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'countries';
  protected getServiceIdentifier(): string {
    return 'CountryApiService';
  }

  initialize(): Observable<IndexableType | undefined> {
    return from(Network.getStatus()).pipe(
      switchMap(({ connected }) => {
        if (connected) {
          return this.dataService.isCollectionSynced(this.dbPath).pipe(
            switchMap((isSynced) => {
              if (!isSynced) {
                const endpoint = `${this.url}/${this.dbPath}`;
                const queryOptions: QueryOptions = { limit: 50, orderBy: 'code' };
                return this.dataService
                  .fetchAndStoreInChunks(this.dbPath, endpoint, queryOptions, 50)
                  .pipe(switchMap(() => this.dataService.setCollectionSynced(this.dbPath, true)));
              }
              return of(undefined);
            }),
          );
        }
        return of(undefined);
      }),
    );
  }

  getSyncStatus(): Observable<boolean> {
    return this.dataService.isCollectionSynced(this.dbPath);
  }

  search(searchTerm: string): Observable<Country[]> {
    const searchFields = ['name', 'code']; // Add more fields as needed
    return this.dataService.searchLocalData(this.dbPath, searchFields, searchTerm);
  }

  override getAll(queryOptions: QueryOptions): Observable<Country[]> {
    return this.dataService.getAllLocalData<Country>(this.dbPath);
  }
}
