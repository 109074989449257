import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-dots',
  template: `<span>{{ dots }}</span>`,
  standalone: true,
  imports: [CommonModule],
})
export class LoadingDotsComponent implements OnInit, OnDestroy {
  dots = '';
  private interval: any;

  ngOnInit() {
    this.interval = setInterval(() => {
      this.dots = this.dots.length >= 3 ? '' : this.dots + '.';
    }, 300);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
