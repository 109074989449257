import { Component, forwardRef, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-otp-input',
  template: `
    <div class="w-full flex items-center justify-center gap-2">
      @for (idx of otpIndices; track $index) {
        <input
          [(ngModel)]="otpValues[$index]"
          (keyup)="onKeyUp($event, $index)"
          (input)="validateInput($event, $index)"
          maxlength="1"
          type="tel"
          pattern="[0-9]*"
          inputmode="numeric"
          class="w-12 h-12 border-b-2 border-primary-300 outline-none focus:border-primary-500 text-center otp-field text-lg font-bold"
          [disabled]="disabled"
          #otpInput />
      }
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtpInputComponent),
      multi: true,
    },
  ],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  standalone: true,
  imports: [FormsModule],
})
export class OtpInputComponent implements ControlValueAccessor {
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef<HTMLInputElement>>;

  otpIndices = Array.from({ length: 6 }, (_, i) => i);
  otpValues: string[] = Array(6).fill('');
  disabled = false;
  onChange = (value: any) => {};
  onTouched = () => {};

  onKeyUp(event: KeyboardEvent, index: number): void {
    this.onTouched();
    let nextTargetIndex = event.key === 'Backspace' ? index - 1 : index + 1;
    if ((event.key === 'Backspace' && this.otpValues[index] === '') || this.otpValues[index].length === 1) {
      if (nextTargetIndex >= 0 && nextTargetIndex < this.otpIndices.length) {
        const targetElement = this.otpInputs.toArray()[nextTargetIndex]?.nativeElement;
        if (targetElement) {
          setTimeout(() => targetElement.focus(), 0); // Ensure focus change occurs outside of any current DOM updates
        }
      }
    }

    this.onChange(this.otpValues.join(''));
  }

  validateInput(event: Event, index: number): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/\D/g, ''); // Ensures only digits
    this.otpValues[index] = inputElement.value;
    this.onChange(this.otpValues.join(''));
  }

  writeValue(value: string): void {
    if (value && value.length === this.otpIndices.length) {
      this.otpValues = value.split('');
    } else if (value === '') {
      this.otpValues = Array(6).fill('');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
