import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { Template } from 'src/app/domain';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';
import { Observable, switchMap, of } from 'rxjs';
import { QueryOptions } from '../types';

@Injectable({
  providedIn: 'root',
})
export class TemplateAPIService extends CoreApiService<Template> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<Template>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'templates';
  protected getServiceIdentifier(): string {
    return 'TemplateApiService';
  }

  override getAll(queryOptions: QueryOptions): Observable<Template[]> {
    return this.dataService.getAllLocalData<Template>(this.dbPath).pipe(
      switchMap((data) => {
        if (data && data.length > 0) {
          return of(data);
        } else {
          return super.getAll(queryOptions);
        }
      }),
    );
  }
}
