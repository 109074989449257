import { inject, Injectable } from '@angular/core';
import { CoreStore } from './core.store';
import { MonthlyAuditLog } from '../domain';
import { AuditState } from './states';
import { AuditApiService } from '../services/apis';
import { catchError, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditStore extends CoreStore<MonthlyAuditLog, AuditState, AuditApiService> {
  override serviceType = AuditApiService;
  constructor() {
    super({ entities: [], loading: false });
  }

  monthlyAuditLogs = this.selectSignal((state) => state.monthlyAuditLogs);

  readonly loadMonthlyAuditLogs = this.effect<{
    mapId: string;
    callback?: (data: MonthlyAuditLog[]) => void;
  }>((params$) => {
    return params$.pipe(
      switchMap((params) => {
        return this.service.loadLatestMonthlyAuditLogs(params.mapId).pipe(
          tap({
            next: (response) => {
              this.patchState({
                entities: response,
                loading: false,
                monthlyAuditLogs: response,
              });
              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: Error) => {
              this.patchState({ error: error.message, loading: false, monthlyAuditLogs: [] });
            },
          }),
          catchError((error: Error) => {
            console.error('Caught error:', error);
            this.patchState({ error: error.message, loading: false, monthlyAuditLogs: [] });
            return of([]);
          }),
        );
      }),
    );
  });
}
