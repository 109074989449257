import { Pipe, PipeTransform } from '@angular/core';
import { DefaultMapLocation } from 'src/app/domain';

@Pipe({
  name: 'location',
  standalone: true,
})
export class LocationPipe implements PipeTransform {
  transform(value: DefaultMapLocation | string): string {
    const location = value as DefaultMapLocation | string;

    if (typeof location === 'string') {
      return location;
    }
    if (!location) {
      return 'Select your location';
    }

    const fields = [
      location.location,
      location.level4?.name,
      location.level3?.name,
      location.level2?.name,
      location.level1?.name,
      location.country?.name,
    ];

    const selectedText = fields.find((field) => field && field !== '');
    return selectedText || '';
  }
}
