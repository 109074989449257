import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastMessage {
  title: string;
  message: string;
  icon?: string;
  button?: { text: string; action: () => void };
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new Subject<ToastMessage>();
  toastState = this.toastSubject.asObservable();

  constructor() {}

  showToast(toast: ToastMessage) {
    this.toastSubject.next(toast);
  }
}
