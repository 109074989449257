import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { MonthlyAuditLog } from 'src/app/domain';
import { catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';

@Injectable({
  providedIn: 'root',
})
export class AuditApiService extends CoreApiService<MonthlyAuditLog> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<MonthlyAuditLog>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'audits';

  protected getServiceIdentifier(): string {
    return 'AuditApiService';
  }

  loadLatestMonthlyAuditLogs(mapId: string): Observable<MonthlyAuditLog[]> {
    if (!mapId) return of([]);
    const endpoint = `${this.url}/${this.dbPath}/get-latest-monthly/${mapId}`;

    if (navigator.onLine) {
      return this.http.get<MonthlyAuditLog[]>(endpoint).pipe(
        switchMap((response) => this.dataService.setLocalData(this.dbPath, mapId, response).pipe(map(() => response))),
        catchError((error) =>
          from(this.dataService.getLocalData<MonthlyAuditLog[]>(this.dbPath, mapId)).pipe(
            switchMap((localData) => {
              if (localData) {
                return of(localData);
              } else {
                return throwError(() => error);
              }
            }),
          ),
        ),
      );
    } else {
      return from(this.dataService.getLocalData<MonthlyAuditLog[]>(this.dbPath, mapId)).pipe(
        switchMap((data) => {
          if (data) {
            return of(data);
          } else {
            return throwError(() => new Error('Offline and no local data available'));
          }
        }),
      );
    }
  }
}
