import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-sheet',
  standalone: true,
  template: `
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-end " style="z-index:10000">
      <div class="bg-white rounded-t-3xl p-4 w-full max-w-lg h-auto flex flex-col" @modalAnimation>
        <div class="w-full h-[18px] pt-1 flex-col justify-center items-center gap-2.5 inline-flex">
          <div class="w-10 h-1.5 relative">
            <div class="w-10 h-1.5 left-0 top-0 absolute bg-gray-100 rounded-full"></div>
          </div>
        </div>
        <div class="w-full h-full flex-col justify-center items-center gap-2.5 inline-flex pb-32 pt-8">
          @if (!error) {
            <img ngSrc="assets/icons/svg/checkbox.svg" alt="logo" priority width="100" height="100" />
          } @else {
            <img ngSrc="assets/icons/svg/info.svg" alt="logo" priority width="100" height="100" />
          }

          <p class="w-full text-center text-black text-2xl font-bold font-['Roboto'] leading-9">
            {{ text }}
          </p>

          @if (buttonText.length > 0) {
            <p class="text-center text-gray-800 text-base font-bold font-['Roboto'] leading-normal">
              {{ buttonText }}
            </p>
          }
        </div>

        <div class="w-full flex flex-row justify-between items-center fixed bottom-8 left-0 right-0 px-4">
          <button
            class="w-full mt-4  px-4 py-2 rounded text-center text-gray-800 text-base font-bold font-['Roboto'] leading-normal"
            (click)="onClose()">
            Close
          </button>
        </div>
      </div>
    </div>
  `,
  imports: [NgOptimizedImage],
  animations: [
    trigger('modalAnimation', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(100%)',
        }),
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        }),
      ),
      transition(':enter, :leave', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ModalAlertSheetComponent {
  @Input() text: string = '';
  @Input() error: boolean = false;
  @Input() buttonText: string = '';
  @Output() closeModal = new EventEmitter<any>();

  onClose(data?: any) {
    this.closeModal.emit(data ?? 'OK');
  }
}
