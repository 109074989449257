import { Injectable, inject } from '@angular/core';
import { MapState } from './states';
import { Map, Node } from '../domain/models/map.model';
import {
  EMPTY,
  catchError,
  finalize,
  from,
  of,
  switchMap,
  tap,
  withLatestFrom,
  Observable,
  map,
  firstValueFrom,
} from 'rxjs';
import { MonthlyAuditLog } from '../domain/models/audit.model';
import { getNodeWithDescendants, MapRoleType, parseMapId, parseUserId } from '../shared';
import { TemplateStore } from './template.store';
import { MapPermissionStore } from './map-permission.store';
import { CoreStore } from './core.store';
import { MapConnectionStore } from './map-connection.store';
import { MapApiService } from '../services/apis';
import { MapConnection, MapPermission } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class MapStore extends CoreStore<Map, MapState, MapApiService> {
  override serviceType = MapApiService;

  templateStore = inject(TemplateStore);
  mapPermissionStore = inject(MapPermissionStore);
  mapConnectionStore = inject(MapConnectionStore);

  constructor() {
    super({ entities: [], loading: false, auditLoading: false });
  }

  readonly auditLoading$ = this.select((state) => state.auditLoading);
  readonly selectAudits$ = this.select((state) => state.audits);
  readonly selectReadOnly$ = this.select((state) => state.readonly);
  readonly selectUsers$ = this.select((state) => state.users);

  // State updaters

  readonly setAuditLoading = this.updater((state, auditLoading: boolean) => ({
    ...state,
    auditLoading,
  }));

  readonly setAudits = this.updater((state, audits: MonthlyAuditLog[]) => ({
    ...state,
    audits: audits,
  }));

  readonly setReadOnly = this.updater((state, readonly: boolean) => ({
    ...state,
    readonly: readonly,
  }));

  // Effects
  override readonly createEntity = this.effect<{
    entity: Map | Partial<Map>;
    callback?: (data: Map) => void;
  }>((params$) =>
    params$.pipe(
      switchMap((params) => {
        this.setLoading(true);
        return this.service.create(params.entity as Map).pipe(
          tap({
            next: (response) => {
              this.setLoading(false);
              this.updater((state) => ({
                ...state,
                entities: [...state.entities, response],
              }))();
              this.mapPermissionStore.createEntity({
                entity: {
                  mapId: response.id!,
                  userId: this.service.getUserId()!,
                  role: 'admin',
                },
              });

              if (params.callback) {
                params.callback(response);
              }
            },
            error: (error: any) => {
              this.setError(error.message);
            },
          }),
          catchError((error) => {
            this.setError(error.message);
            return of([]);
          }),
        );
      }),
    ),
  );

  readonly moveEntity = this.effect<{
    mapId: string;
    sourceNodeId: string;
    destinationNodeId: string;
    callback?: () => void;
  }>((params$) =>
    params$.pipe(
      switchMap((params) =>
        this.service.get(params.mapId).pipe(
          switchMap((map) => {
            if (!map) {
              this.setError('Map not found');
              return EMPTY;
            }

            const sourceNode = map.nodes.find((node) => node.id === params.sourceNodeId);
            const destinationNode = map.nodes.find((node) => node.id === params.destinationNodeId);

            if (!sourceNode || !destinationNode) {
              this.setError('Source or destination node not found');
              return EMPTY;
            }

            if (this.isDescendant(map.nodes, sourceNode.id!, destinationNode.id!)) {
              this.setError('Cannot move a node to its own descendant');
              return EMPTY;
            }

            const updatedNodes = this.moveNodeAndChildren(map.nodes, sourceNode.id!, destinationNode.id!);
            const updatedMap: Map = { ...map, nodes: updatedNodes };

            return this.service.update(params.mapId, updatedMap).pipe(
              tap({
                next: (updatedMap) => {
                  this.updateEntity({ id: updatedMap.id!, entity: updatedMap });
                  if (params.callback) {
                    params.callback();
                  }
                },
                error: (error: any) => {
                  this.setError(error.message);
                },
              }),
              catchError((error) => {
                this.setError(error.message);
                return EMPTY;
              }),
            );
          }),
        ),
      ),
    ),
  );

  readonly cloneEntity = this.effect<{
    id: string;
    referralCode: string;
    callback?: () => void;
  }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) =>
        from(this.service.get(params.id)).pipe(
          switchMap((entity) => {
            if (!entity) {
              this.setError('No entity found to clone');
              this.setLoading(false);
              return EMPTY;
            }

            const tempId = Date.now().toString(); // Temporary ID for optimistic UI update
            const optimisticEntity = {
              ...entity,
              id: tempId, // Assign a temporary ID
              name: `${entity.name} (copy)`,
              createdAt: new Date().toISOString(),
              referralCode: params.referralCode,
            };

            // Optimistically update the state
            this.updater((state) => ({
              ...state,
              entities: [...state.entities, optimisticEntity],
            }))();

            return from(
              this.service.create({
                ...entity,
                name: `${entity.name} (copy)`,
                createdAt: new Date().toISOString(),
                referralCode: params.referralCode,
              }),
            ).pipe(
              tap({
                next: (newEntity) => {
                  // Replace the optimistic entity with the actual entity from the server
                  this.updater((state) => ({
                    ...state,
                    entities: state.entities.map((e) => (e.id === tempId ? newEntity : e)),
                  }))();
                  if (params.callback) {
                    params.callback();
                  }
                },
                error: (error: any) => {
                  this.setError('An error occurred while cloning map: ' + error.message);
                  // Revert optimistic update on error
                  this.updater((state) => ({
                    ...state,
                    entities: state.entities.filter((e) => e.id !== tempId),
                  }))();
                },
              }),
              finalize(() => this.setLoading(false)),
              catchError((error) => {
                this.setError('An error occurred while cloning map: ' + error.message);
                return of([]); // Continue the observable chain despite the error
              }),
            );
          }),
        ),
      ),
    ),
  );

  readonly deleteNode = this.effect<{ mapId: string; nodeId: string; callback?: () => void }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)), // Set loading at the start
      switchMap((params) => {
        // Capturing previous state for potential rollback
        const previousEntities = this.get().entities;
        const entity = previousEntities.find((entity) => entity.id === params.mapId);

        if (!entity) {
          return EMPTY;
        }

        const updateEntity = {
          ...entity,
          nodes: entity.nodes.filter((node) => node.id !== params.nodeId),
        };

        // Optimistically update the state to remove the node
        this.updater((state) => ({
          ...state,
          entities: state.entities.map((entity) => (entity.id === params.mapId ? updateEntity : entity)),
        }))();

        return from(this.service.update(params.mapId, updateEntity)).pipe(
          tap({
            next: () => {
              // On success, nothing more needs to be done since the state is already updated
              if (params.callback) {
                params.callback();
              }
            },
            error: (error: any) => {
              // On error, restore the previous state
              this.setError('An error occurred while deleting map: ' + error.message);
              this.updater(() => ({ entities: previousEntities, loading: false }))();
            },
          }),
          finalize(() => this.setLoading(false)), // Handle loading state at the end
          catchError(() => EMPTY), // Ensure the flow continues even in case of error
        );
      }),
    ),
  );

  readonly transferOwnership = this.effect<{ mapId: string; nodeId: string; userId: string; callback?: () => void }>(
    (params$) =>
      params$.pipe(
        withLatestFrom(this.entities$),
        switchMap(([params, entities]) => {
          if (entities.length === 0) {
            return of(
              this.loadEntities({
                useCache: false,
                callback: (entities) => {
                  return this.transferOwnershipLogic(params);
                },
              }),
            );
          }
          return this.transferOwnershipLogic(params);
        }),
      ),
  );

  readonly lock = this.effect<{ mapId: string; lock: boolean; callback?: () => void }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) => {
        // Capture the previous state for potential rollback
        const previousEntities = this.get().entities;

        // Optimistically update the lock status of the entity
        this.updater((state) => ({
          ...state,
          entities: state.entities.map((entity) =>
            entity.id === params.mapId ? { ...entity, isLocked: params.lock } : entity,
          ),
        }))();

        return from(this.service.lock(params.mapId, params.lock)).pipe(
          tap({
            next: (updatedEntity) => {
              // Confirm the state with the actual updated entity from the server
              this.updater((state) => ({
                ...state,
                entities: state.entities.map((entity) => (entity.id === updatedEntity.id ? updatedEntity : entity)),
              }))();
              if (params.callback) {
                params.callback();
              }
            },
            error: (error: any) => {
              this.setError('An error occurred while locking map: ' + error.message);
              // Revert optimistic update on error
              this.updater(() => ({ entities: previousEntities, loading: false }))();
            },
          }),
          finalize(() => this.setLoading(false)),
          catchError(() => EMPTY), // Continue the observable chain despite the error
        );
      }),
    ),
  );

  readonly updateMemberAccess = this.effect<{
    mapId: string;
    userId: string;
    role: MapRoleType;
    callback?: () => void;
    error?: (error: string) => void;
  }>((params$) =>
    params$.pipe(
      withLatestFrom(this.mapPermissionStore.entities$),
      tap(() => this.setLoading(true)),
      switchMap(([params, mapPermissionEntities]) => {
        const previousEntities = this.get().entities;
        const entity = previousEntities.find((entity) => entity.id === params.mapId);

        if (!entity) {
          return EMPTY;
        }

        const userPermission = mapPermissionEntities.find(
          (permission) => permission.mapId === params.mapId && permission.userId === params.userId,
        );

        if (!userPermission) {
          return EMPTY;
        }

        return of(
          this.mapPermissionStore.updateEntity({
            id: userPermission.id!,
            entity: { ...userPermission, role: params.role },
          }),
        );
      }),
    ),
  );

  readonly updateOwner = this.effect<{
    mapId: string;
    userId: string;
    callback?: () => void;
    error?: (error: string) => void;
  }>((params$) =>
    params$.pipe(
      withLatestFrom(this.mapPermissionStore.entities$),
      tap(() => this.setLoading(true)),
      switchMap(([params, mapPermissionEntities]) => {
        const previousEntities = this.get().entities;
        const entity = previousEntities.find((entity) => entity.id === params.mapId);

        if (!entity) {
          return EMPTY;
        }

        const userPermission = mapPermissionEntities.find(
          (permission) => permission.mapId === params.mapId && permission.userId === params.userId,
        );

        if (!userPermission) {
          return EMPTY;
        }

        return of(
          this.mapPermissionStore.updateEntity({
            id: userPermission.id!,
            entity: { ...userPermission, role: 'admin' },
          }),
        );
      }),
    ),
  );

  readonly deleteMember = this.effect<{
    mapId: string;
    userId: string;
    callback?: () => void;
    error?: (error: string) => void;
  }>((params$) =>
    params$.pipe(
      withLatestFrom(this.mapPermissionStore.entities$),
      tap(() => this.setLoading(true)),
      switchMap(([params, mapPermissionEntities]) => {
        // Capture the previous state for potential rollback
        const previousEntities = this.get().entities;
        const entity = previousEntities.find((entity) => entity.id === params.mapId);

        if (!entity) {
          return EMPTY;
        }

        const userPermission = mapPermissionEntities.find(
          (permission) => permission.mapId === params.mapId && permission.userId === params.userId,
        );

        if (!userPermission) {
          return EMPTY;
        }

        return of(this.mapPermissionStore.deleteEntity({ id: userPermission.id! }));
      }),
    ),
  );

  readonly generatePDF = this.effect<{ mapId: string; nodeId: string; callback?: () => void }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) =>
        this.service.generatePDF(params.mapId, params.nodeId).pipe(
          tap({
            next: (res) => {
              if (params.callback) {
                params.callback();
              }
            },
            error: (err) => {
              console.error('PDF generation failed', err);
            },
          }),
          finalize(() => this.setLoading(false)),
        ),
      ),
    ),
  );

  readonly copyMap = this.effect<{ mapId: string; callback?: () => void }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) =>
        this.service.get(params.mapId).pipe(
          switchMap((map) => {
            if (!map) {
              this.setError('Map not found');
              return EMPTY;
            }

            return of(
              this.createEntity({
                entity: { ...map, name: map.name + ' - Copy' },
                callback: params.callback,
              }),
            );
          }),
        ),
      ),
    ),
  );

  readonly redeemSplitOffStream = this.effect<{ redeemCode: string; callback?: (entity: MapConnection) => void }>(
    (params$) => params$.pipe(switchMap((params) => from(this.handleRedeemSplitOffStream(params)))),
  );

  readonly redeemUpstream = this.effect<{
    redeemCode: string;
    upstreamMapId: string;
    upstreamNodeId: string;
    callback?: (connection: MapConnection) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) => {
        return of(
          this.mapConnectionStore.redeemJoinUpStream({
            redeemCode: params.redeemCode,
            upstreamMapId: parseMapId(params.upstreamMapId),
            upstreamNodeId: params.upstreamNodeId,
            callback: (updatedConnection) => {
              params.callback?.(updatedConnection);
            },
          }),
        );
      }),
    ),
  );

  readonly loadMapWithInviteCode = this.effect<{
    redeemCode: string;
    callback?: (entity: Map | null, role: MapRoleType) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap(({ redeemCode, callback }) =>
        from(this.loadMapPermission(redeemCode)).pipe(
          switchMap((permissions) => this.handlePermission(permissions, callback)),
        ),
      ),
      finalize(() => this.setLoading(false)),
    ),
  );

  readonly loadMapWithJoinCode = this.effect<{
    redeemCode: string;
    downstreamMapId: string;
    callback?: (entity: any) => void;
  }>((params$) =>
    params$.pipe(
      tap(() => this.setLoading(true)),
      switchMap((params) =>
        this.mapConnectionStore.getByRedeemCode(params.redeemCode).pipe(
          switchMap((connection) => {
            if (!connection) {
              this.setError('No connection found with the referral code');
              return EMPTY;
            }
            const downstreamMapId = parseMapId(params.downstreamMapId);
            const upstreamMapId = parseMapId(connection.upstreamMapId);

            if (parseMapId(connection.downstreamMapId) && parseMapId(connection.downstreamMapId)?.length > 0) {
              this.setError('Connection already redeemed');
              return EMPTY;
            }

            connection.upstreamMapId = upstreamMapId!;
            connection.downstreamMapId = downstreamMapId;
            return of(
              this.mapConnectionStore.updateEntity({
                id: connection.id!,
                entity: connection,
                callback: (updatedConnection) => {
                  params.callback?.(updatedConnection);
                },
              }),
            );
          }),
        ),
      ),
      catchError((error) => {
        this.setError(`Error in loadMapWithJoinCode: ${error.message}`);
        return EMPTY;
      }),
      finalize(() => this.setLoading(false)),
    ),
  );

  private handleUpdatedConnection(updatedConnection: MapConnection, callback?: (entity: any) => void) {
    const downstreamMapId = parseMapId(updatedConnection.downstreamMapId);
    const upstreamMapId = parseMapId(updatedConnection.upstreamMapId);

    if (!upstreamMapId) {
      this.setError('Upstream map not found');
      return;
    }

    this.mapPermissionStore.loadEntities({
      options: {
        where: [
          {
            field: 'mapId',
            operator: '==',
            value: upstreamMapId!,
          },
        ],
      },
      callback: (upstreamPermissions) => {
        this.createDownstreamPermissions(upstreamPermissions, downstreamMapId!, () => {
          if (callback) {
            callback(updatedConnection);
          }
        });
      },
    });
  }

  private createDownstreamPermissions(
    upstreamPermissions: MapPermission[],
    downstreamMapId: string,
    callback: () => void,
  ) {
    let createdCount = 0;
    const totalPermissions = upstreamPermissions.length;

    if (totalPermissions === 0) {
      callback();
      return;
    }

    upstreamPermissions.forEach((permission) => {
      const userId = parseUserId(permission.userId!);
      this.mapPermissionStore.createEntity({
        entity: {
          mapId: downstreamMapId,
          userId,
          role: 'editor',
        },
        callback: () => {
          createdCount++;
          if (createdCount === totalPermissions) {
            callback();
          }
        },
      });
    });
  }

  private async loadMapPermission(redeemCode: string) {
    return new Promise<MapPermission[]>((resolve) => {
      this.mapPermissionStore.loadEntities({
        options: {
          where: [
            {
              field: 'redeemCode',
              operator: '==',
              value: redeemCode,
            },
          ],
        },
        callback: (permissions) => resolve(permissions),
      });
    });
  }

  private handlePermission(
    permissions: MapPermission[],
    callback?: (entity: Map | null, role: MapRoleType) => void,
  ): Observable<Map | never> {
    if (permissions.length === 0) {
      this.setError('No map found with the referral code');
      return EMPTY;
    }

    const permission = permissions[0];
    if (this.isMapObject(permission.mapId)) {
      callback?.(permission.mapId, permission.role);
      return EMPTY;
    }

    const loadEntity = async (id: string) => {
      return new Promise<Map | null>((resolve) => {
        this.loadEntity({
          id,
          callback: (map) => resolve(map),
        });
      });
    };

    return from(loadEntity(permission.mapId)).pipe(
      switchMap((map) => {
        if (!map) {
          this.setError('Map not found');
          return EMPTY;
        }
        callback?.(map, permission.role);
        return of(map);
      }),
    );
  }

  private isMapObject(mapId: unknown): mapId is Map {
    return typeof mapId === 'object' && mapId !== null;
  }

  private async handleRedeemSplitOffStream(params: { redeemCode: string; callback?: (entity: MapConnection) => void }) {
    const connection = await firstValueFrom(this.mapConnectionStore.getByRedeemCode(params.redeemCode));

    if (!connection) return null;

    const upstreamMapId = parseMapId(connection.upstreamMapId);
    const map = await this.loadMap(upstreamMapId!);
    if (!map) {
      this.patchState({ error: 'Map not found', loading: false });
      return null;
    }

    const node = map.nodes.find((node) => node.id === connection.upstreamNodeId);

    connection.upstreamMapId = upstreamMapId!;

    const newMap = await this.createSplitOffMap(map, connection.upstreamNodeId);
    connection.upstreamNodeId = node?.parentId ?? '';
    await this.updateConnection(connection, newMap.id!);
    // await this.copyMapPermissions(map.id!, newMap.id!);
    await this.updateCurrentMapAndRemoveTransferredNodes(map, node?.id!);

    if (params.callback) {
      params.callback(connection);
    }

    return connection;
  }

  private async loadMap(mapId: string): Promise<Map | null> {
    return new Promise((resolve) => {
      this.loadEntity({
        id: mapId,
        callback: (map) => resolve(map || null),
      });
    });
  }

  private async createSplitOffMap(originalMap: Map, upstreamNodeId: string): Promise<Map> {
    const nodes = getNodeWithDescendants(originalMap.nodes, upstreamNodeId);
    const newMap: Map = {
      ...originalMap,
      name: originalMap.name + ' - Split Off',
      nodes,
    };

    return new Promise((resolve) => {
      this.createEntity({
        entity: newMap,
        callback: (createdMap) => resolve(createdMap),
      });
    });
  }

  private async updateConnection(connection: MapConnection, newMapId: string): Promise<void> {
    return new Promise((resolve) => {
      this.mapConnectionStore.updateEntity({
        id: connection.id!,
        entity: {
          ...connection,
          upstreamMapId: parseMapId(connection.upstreamMapId)!,
          downstreamMapId: newMapId,
          redeemed: true,
          redeemedAt: new Date().toISOString(),
          redeemedBy: this.service.getUserId()!,
        },
        callback: () => resolve(),
      });
    });
  }

  private async copyMapPermissions(sourceMapId: string, targetMapId: string): Promise<void> {
    let permissions = (await this.loadMapPermissions(sourceMapId)).map((permission) => ({
      ...permission,
      userId: parseUserId(permission.userId!),
    }));

    const uniquePermissions = new Map<string, (typeof permissions)[0]>();

    // Iterate through the permissions and add to the Map, which will ensure uniqueness by userId
    for (const permission of permissions) {
      const userId = permission.userId;
      uniquePermissions.set(userId!, { ...permission, mapId: targetMapId });
    }

    // Convert the Map values back to an array for creating permissions
    let distinctPermissions = Array.from(uniquePermissions.values());
    distinctPermissions = distinctPermissions.filter((permission) => permission.userId !== this.service.getUserId());

    // Create the permissions for the target map
    const createPermissionPromises = distinctPermissions.map((permission) => this.createMapPermission(permission));

    await Promise.all(createPermissionPromises);
  }

  private async loadMapPermissions(mapId: string): Promise<MapPermission[]> {
    if (!mapId) return [];
    return new Promise((resolve) => {
      this.mapPermissionStore.loadEntities({
        useCache: false,
        options: {
          where: [
            {
              field: 'mapId',
              operator: '==',
              value: mapId,
            },
          ],
        },
        callback: (permissions: MapPermission[]) => resolve(permissions),
      });
    });
  }

  private async createMapPermission(permission: MapPermission): Promise<void> {
    return new Promise((resolve) => {
      this.mapPermissionStore.createEntity({
        entity: permission,
        callback: () => resolve(),
      });
    });
  }

  private async updateCurrentMapAndRemoveTransferredNodes(map: Map, nodeId: string): Promise<void> {
    const transferableNodes = getNodeWithDescendants(map.nodes, nodeId);
    if (!transferableNodes || transferableNodes.length === 0) {
      this.setError('No transferable nodes found');
      return;
    }

    const updatedCurrentMap: Map = {
      ...map,
      nodes: map.nodes.filter((node) => !transferableNodes.some((tn) => tn.id === node.id)),
    };

    return new Promise((resolve) => {
      this.updateEntity({
        id: map.id!,
        entity: updatedCurrentMap,
        callback: () => resolve(),
      });
    });
  }

  private transferOwnershipLogic(params: {
    mapId: string;
    nodeId: string;
    userId: string;
    callback?: () => void;
  }): Observable<{ newMap: Map; updatedCurrentMap: Map }> {
    return of(params).pipe(
      withLatestFrom(this.entities$),
      tap(() => this.setLoading(true)),
      switchMap(([params, entities]) => {
        const currentEntity = entities.find((entity) => entity.id === params.mapId);
        if (!currentEntity) {
          this.setError('Map not found');
          return EMPTY;
        }

        if (currentEntity.lock) {
          this.setError('Map is locked');
          return EMPTY;
        }

        const transferableNodes = getNodeWithDescendants(currentEntity.nodes, params.nodeId);
        if (!transferableNodes || transferableNodes.length === 0) {
          this.setError('No transferable nodes found');
          return EMPTY;
        }

        const newEntity: Map = {
          ...currentEntity,
          nodes: transferableNodes,
        };

        return this.service.create(newEntity).pipe(
          switchMap((newMap) => {
            // Update the original map
            const updatedCurrentMap = {
              ...currentEntity,
              nodes: currentEntity.nodes.filter((node) => !transferableNodes.some((tn) => tn.id === node.id)),
            } as Map;

            return this.service
              .update(params.mapId, updatedCurrentMap)
              .pipe(map(() => ({ newMap, updatedCurrentMap })));
          }),
          tap({
            next: ({ newMap, updatedCurrentMap }) => {
              this.updateEntity({ id: params.mapId, entity: updatedCurrentMap });
              this.addEntity(newMap);
              if (params.callback) {
                params.callback();
              }
            },
            error: (error: any) => {
              this.setError(error.message);
            },
          }),
          catchError((error) => {
            this.setError(error.message);
            return EMPTY;
          }),
          finalize(() => this.setLoading(false)),
        );
      }),
    );
  }

  private addEntity(entity: Map) {
    this.updater((state) => ({
      ...state,
      entities: [...state.entities, entity],
    }))();
  }

  private isDescendant(nodes: Node[], potentialAncestorId: string, nodeId: string): boolean {
    const node = nodes.find((n) => n.id === nodeId);
    if (!node || !node.parentId) {
      return false;
    }
    if (node.parentId === potentialAncestorId) {
      return true;
    }
    return this.isDescendant(nodes, potentialAncestorId, node.parentId);
  }

  private moveNodeAndChildren(nodes: Node[], sourceNodeId: string, destinationNodeId: string): Node[] {
    const getDescendantIds = (nodeId: string): string[] => {
      const descendants = nodes.filter((n) => n.parentId === nodeId);
      return [nodeId, ...descendants.flatMap((d) => getDescendantIds(d.id!))];
    };

    const nodesToMove = getDescendantIds(sourceNodeId);

    return nodes.map((node) => {
      if (nodesToMove.includes(node.id!)) {
        if (node.id === sourceNodeId) {
          return { ...node, parentId: destinationNodeId };
        } else {
          return node;
        }
      }
      return node;
    });
  }
}
